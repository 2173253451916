<template>
  <div>
    <h2 class="page-title">Categories</h2>

    <div class="mt-14">
      <div class="text-2xl font-bold">Sub Categories</div>
    </div>
    <table>
      <thead>
        <tr>
          <!-- <th>&nbsp;</th> -->
          <th>Name</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="category in categories" :key="category.id" :class="{
          deleting: deletingRow === category.id,
        }">
          <!-- <td>-</td> -->
          <td>{{ category.name }}</td>
          <td class="action" :class="{
            active: openAction == category.id,
            inactive: openAction != category.id,
          }" @click="toggleActionMenu(category.id)">
            Manage
            <ul v-show="openAction === category.id">
              <li class="p-3" @click="addEdit(category, 'drink')">Edit</li>
              <!-- <li class="p-3">
                <router-link
                  :to="{ name: 'SubCategories', params: { id: category.id } }"
                >
                  Sub Categories
                </router-link>
              </li> -->
              <li class="p-3 bg-red-400 text-white" @click="destroy(category)">
                Delete
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <table>
      <tfoot>
        <tr>
          <th class="text-left p-3">
            <btn :type="'button'">
              <span class="block px-10" @click="addEdit({ ...newItemData }, 'drink')">Add Sub Category</span>
            </btn>
          </th>
          <th class="text-right pt-3">
            <btn :loading="pageLoading" :type="'button'" :light="true" :disabled="pagination.current_page <= 1"
              @clicked="init(pagination.current_page - 1, 'drink')">
              Previous
            </btn>
            <div class="mx-10 font-normal">
              Page {{ pagination.current_page }} of {{ pagination.total_pages }}
            </div>
            <btn :loading="pageLoading" :type="'button'" :light="true"
              :disabled="pagination.current_page >= pagination.total_pages"
              @clicked="init(pagination.current_page + 1, 'drink')">
              Next
            </btn>
          </th>
        </tr>
      </tfoot>
    </table>
    <div class="mobile-tfoot">
      <div>
        <btn :type="'button'" @clicked="addEdit({ ...newItemData }, 'drink')">
          <span class="block px-10">Add Sub Category</span>
        </btn>
      </div>
      <div class="pb-5">
        <btn :loading="pageLoading" :type="'button'" :light="true" :disabled="pagination.current_page == 1">
          Previous
        </btn>
        <div class="mx-10 font-normal">
          Page {{ pagination.current_page }} of {{ pagination.total_pages }}
        </div>
        <btn :loading="pageLoading" :type="'button'" :light="true"
          :disabled="pagination.current_page >= pagination.total_pages">
          Next
        </btn>
      </div>
    </div>

    <AddEditPopup v-if="showAddEditPopup" @closeAddEdit="closeAddEdit" :addEditData="addEditData"></AddEditPopup>
  </div>
</template>

<script>
import AddEditPopup from "./add_edit";

export default {
  name: "barsList",
  components: {
    AddEditPopup,
  },
  data() {
    return {
      category: null,
      categories: [],
      openAction: 0,
      showAddEditPopup: false,
      newItemData: {
        parent_id: null,
        name: null,
        image: null,
        type: null,
      },
      addEditData: {},
      pagination: {},
      pageLoading: false,
      deletingRow: null,
    };
  },
  created() {
    this.newItemData.parent_id = this.$route.params.id
    // console.log(this.newItemData);
    this.init(1);
  },
  methods: {
    init(page) {
      this.pageLoading = true;
      this.$api.post("admin/category-detail", {
        id: this.newItemData.parent_id
      }).then((response) => {
        this.category = response.data
      });

      this.$api.get("admin/get-sub-categories?cat_id=" + this.$route.params.id + "&page=" + page)
        .then((response) => {
          this.categories = response.categories.data;
          if (response.categories.pagination) {
            this.pagination = response.categories.pagination;
          }
          this.pageLoading = false;
        });
    },
    toggleActionMenu(id) {
      this.openAction = this.openAction === id ? 0 : id;
    },
    addEdit(item, type = '') {
      type;
      item.type = this.category.type;
      this.addEditData = item;
      this.showAddEditPopup = true;
      console.log(item);
      // console.log(this.categories);
    },
    closeAddEdit(reload = false) {
      if (reload) {
        this.init(1);
      }
      this.showAddEditPopup = false;
    },
    destroy(item) {
      if (confirm("It will delete " + item.name)) {
        this.deletingRow = item.id;
        this.$api.post("admin/delete-category", { id: item.id }).then(() => {
          this.init(this.pagination.current_page);
        });
      }
    },
  },
};
</script>