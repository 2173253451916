<template>
  <div class="
      bg-white
      fixed
      inset-0
      w-full
      h-screen
      z-50
      bg-opacity-50
      overflow-x-auto
    ">
    <div class="
        bg-domWhite
        shadow-xl
        w-full
        md:w-1/2
        xl:w-1/3
        mx-auto
        mt-24
        p-5
        px-10
      ">
      <div class="text-2xl font-normal flow-root">
        <span class="float-left">
          {{ popupTitle }}
        </span>
        <span class="float-right">
          <img src="@/assets/images/close.png" alt="" class="cursor-pointer" @click="$emit('closeAddEdit', false)" />
        </span>
      </div>
      <Form @submit="submit" :validation-schema="validate">
        <div>
          <div>
            <ig :class="'w-28 h-28 rounded-full m-auto border'" :src="menuItem.image"></ig>
            <input type="file" ref="image" class="hidden" accept="image/*" @change="onFileChange" />
            <div class="mb-3 mt-2 cursor-pointer text-lg text-center" @click="$refs.image.click()"
              v-if="!selectedBaseDrink">
              Change Image
            </div>
            <div v-if="logoError" class="input-error text-center">
              {{ logoError }}
            </div>
          </div>
        </div>
        <div class="input-box">
          <label for="email-address" class="text-xl">Name</label>
          <div class="relative">
            <!-- <Field name="description" required class="dom-border-input" as="textarea" v-model="menuItem.description" /> -->
            <Field name="name" type="text" required class="dom-border-input" v-model="menuItem.name" />
            <ErrorMessage name="name" class="input-error" />
          </div>
        </div>
        <!-- <div class="input-box">
                    <label for="email-address" class="text-xl">Category</label>
                    <div>
                        <Field name="category_id" required class="dom-border-input" as="select" v-model="menuItem.category_id" @change="getSubCategories">
                            <option disabled :value="null">Select</option>
                            <template v-for="category in categories[menuItem.type]">
                                <option v-if="category.parent_id === 0" :value="category.id" :key="category.id">{{ category.name }}</option>
                            </template>
                        </Field>
                        <ErrorMessage name="category_id" class="input-error" />
                    </div>
                </div>
                <div class="input-box">
                    <label for="email-address" class="text-xl">{{ menuItem.type == 'drink' ? 'Alcoholic Base' : 'Subcategory' }}</label>
                    <div>
                        <Field name="sub_category_id" required class="dom-border-input" as="select" v-model="menuItem.sub_category_id">
                            <option disabled :value="null">Select</option>
                            <option v-for="(category,id) in subCategories" :key="id" :value="id">{{ category }}</option>
                        </Field>
                        <ErrorMessage name="sub_category_id" class="input-error" />
                    </div>
                </div> -->
        <!-- <div class="input-box">
                    <label for="email-address" class="text-xl">Price</label>
                    <div class="relative z-10">
                        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span class="text-gray-500 sm:text-sm">
                            $
                            </span>
                        </div>
                        <Field name="price" type="number" required class="dom-border-input" style="padding-left:30px" v-model="menuItem.price" />
                        <ErrorMessage name="price" class="input-error" />
                    </div>
                </div> -->
        <div class="input-box">
          <label for="email-address" class="text-xl">Description</label>
          <div>
            <template v-if="!selectedBaseDrink">
              <Field name="description" required class="dom-border-input" as="textarea" v-model="menuItem.description" />
            </template>
            <template v-else>
              <textarea class="dom-border-input" readonly v-model="menuItem.description"></textarea>
            </template>
            <ErrorMessage name="description" class="input-error" />
          </div>
        </div>

        <div class="input-box">
          <label for="ingredients" class="text-xl">Ingredients</label>
          <div>
            <textarea class="dom-border-input" v-model="menuItem.ingridiants"></textarea>
            <ErrorMessage name="ingredients" class="input-error" />
          </div>
        </div>

        <div class="input-box">
          <label for="instructions" class="text-xl">Instructions</label>
          <div>
            <textarea class="dom-border-input" v-model="menuItem.instructions"></textarea>
            <ErrorMessage name="instructions" class="input-error" />
          </div>
        </div>

        <div class="my-8 text-center">
          <btn :loading="loadingSaveChanges" :black="true" :type="'submit'">
            <span class="px-4">{{ popupButtonText }}</span>
          </btn>
        </div>
      </Form>
    </div>
    <div class="fixed z-50 h-full w-full inset-0 bg-black bg-opacity-75" v-if="showCropper">
      <div class="w-full md:w-8/12 lg:w-6/12 mx-auto relative mt-20">
        <vue-cropper ref="cropper" :src="imagePreview" :aspect-ratio="1 / 1">
        </vue-cropper>
        <div class="mt-8 mb-4 text-right">
          <btn class="mr-2" @clicked="closeCropper()" :light="true">Cancel</btn>
          <btn @clicked="cropImage()">Select</btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { object, string } from "yup";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import F from "../../helper/function";

export default {
  name: "AddEditDrink",
  components: {
    VueCropper,
  },
  props: {
    addEditData: Object,
    categories: Object,
    baseDrinks: Array,
  },
  data() {
    return {
      validate: object({
        name: string()
          .min(2, "Please enter name")
          .required("Please enter name")
          .nullable(),
        description: string()
          .min(2, "Please enter description")
          .required("Please enter description")
          .nullable(),
      }),
      menuItem: {},
      loadingSaveChanges: false,
      popupTitle: null,
      popupButtonText: null,
      imagePreview: null,
      showCropper: false,
      logoError: false,
      subCategories: [],
      showSuggestions: false,
      imageSelected: false,
      selectedBaseDrink: false,
    };
  },
  created() {
    this.menuItem = { ...this.addEditData };
    this.menuItem.ingridiants = this.menuItem.ingridiants == 'null' ? '' : this.menuItem.ingridiants;
    this.menuItem.instructions = this.menuItem.instructions == 'null' ? '' : this.menuItem.instructions;
    var action = this.menuItem.id ? "Update" : "Add";
    var itemType = this.menuItem.type == "food" ? "Food" : "Drink";
    this.popupTitle = action + " " + itemType;
    this.popupButtonText = action + " " + itemType;
    if (this.menuItem.id) {
      this.getSubCategories();
    }
  },
  methods: {
    onFileChange(e) {
      this.logoError = false;
      const file = e.target.files[0];
      this.imageToBase64(file);
    },
    imageToBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imagePreview = reader.result;
        this.showCropper = true;
      };
    },
    deletePreview() {
      this.imagePreview = null;
    },
    closeCropper() {
      this.showCropper = false;
      this.imagePreview = null;
      this.$refs.image.value = "";
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.menuItem.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.showCropper = false;
      this.$refs.image.value = "";
      this.imageSelected = true;
    },
    getSubCategories() {
      if (this.menuItem.category_id) {
        this.$api
          .post("bar/sub-category-by-category", {
            id: this.menuItem.category_id,
          })
          .then((response) => {
            this.subCategories = response.data;
          });
      }
    },
    submit() {
      this.loadingSaveChanges = true;
      this.menuItem.sub_cat_id = this.menuItem.sub_category_id;
      this.menuItem.reference_id = this.menuItem.reference_id
        ? this.menuItem.reference_id
        : 0;
      var formData = new FormData();
      this.menuItem.branch_id = this.$route.params.id;
      for (var key in this.menuItem) {
        if (key != "image") {
          formData.append(key, this.menuItem[key]);
        }
        // formData.append(key, this.menuItem.sub_category_id);
      }

      if (this.menuItem.image && this.imageSelected) {
        // const file = new File([this.menuItem.image], "menu_item_image.png");
        // formData.append('image', file)
        formData.append(
          "image",
          F.dataURIToBlob(this.menuItem.image),
          "menu_item_image.png"
        );
      } else {
        delete formData["image"];
      }

      var url = "add-menu";
      if (this.menuItem.id) {
        url = "menu-update";
      }
      this.$api
        .post(url, formData)
        .then((response) => {
          this.$toast.info(response.message);
          this.loadingSaveChanges = false;
          this.$emit("closeAddEdit", true, this.menuItem.type);
        })
        .catch(() => {
          this.loadingSaveChanges = false;
        });
    },
    nameChange() {
      if (this.menuItem.name.length > 1) {
        this.showSuggestions = true;
      }
    },
    selectBaseItem(item = false) {
      this.selectedBaseDrink = item;
      if (item) {
        this.showSuggestions = false;
        this.menuItem.name = item.name;
        this.menuItem.description = item.description;
        this.menuItem.image = item.image;
        this.menuItem.reference_id = item.id;
        this.menuItem.ingridiants = item.ingridiants;
        this.menuItem.instructions = item.instructions;
      }
    },
  },
};
</script>