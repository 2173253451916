<template>
  <div>
    <h2 class="page-title">Lookup Drinks</h2>
    <table>
      <thead>
        <tr>
          <th>
            <span class="sm:h-10 sm:w-10 lg:h-auto lg:w-auto block">Logo</span>
          </th>
          <th class="w-10">Sponsored</th>
          <th>Name</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in menu" :key="item.id" :class="{
          deleting: deletingRow === item.id && deletingType == 'drink',
        }">
          <td class="w-20 border-0">
            <ig :src="item.image" class="rounded-full h-12 w-12"></ig>
          </td>
          <td class="text-center">
            <input type="checkbox" :checked="item.is_sponsored == 1" class="w-6 h-6"
              @change="changeSponsored(index, item)" />
          </td>
          <td>{{ item.name }}</td>
          <td class="action" :class="{
            active: openActionDrink == item.id,
            inactive: openActionDrink != item.id,
          }" @click="toggleActionMenu(item.id, 'drink')">
            Manage
            <ul v-show="openActionDrink === item.id">
              <li class="p-3" @click="addEdit('drink', item)">Edit</li>
              <li class="p-3 bg-red-400 text-white" @click="destroy(item)">
                Delete
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>

    <table>
      <tfoot>
        <tr>
          <th class="text-left p-3">
            <btn :type="'button'">
              <span class="block px-10" @click="addEdit('drink', { ...newItemData })">Add Lookup Drink</span>
            </btn>
          </th>
          <th class="text-right pt-3">
            <btn :loading="pageLoading" :type="'button'" :light="true" :disabled="pagination.current_page <= 1"
              @clicked="init(pagination.current_page - 1, 'drink')">
              Previous
            </btn>
            <div class="mx-10 font-normal">
              Page {{ pagination.current_page }} of {{ pagination.total_pages }}
            </div>
            <btn :loading="pageLoading" :type="'button'" :light="true"
              :disabled="pagination.current_page >= pagination.total_pages"
              @clicked="init(pagination.current_page + 1, 'drink')">
              Next
            </btn>
          </th>
        </tr>
      </tfoot>
    </table>

    <div class="mobile-tfoot">
      <div>
        <btn :type="'button'" @clicked="addEdit">
          <span class="block px-10">Add Lookup Drink</span>
        </btn>
      </div>
      <div class="pb-5">
        <btn :loading="pageLoading" :type="'button'" :light="true" :disabled="pagination.current_page == 1">
          Previous
        </btn>
        <div class="mx-10 font-normal">
          Page {{ pagination.current_page }} of {{ pagination.total_pages }}
        </div>
        <btn :loading="pageLoading" :type="'button'" :light="true"
          :disabled="pagination.current_page >= pagination.total_pages">
          Next
        </btn>
      </div>
    </div>

    <AddEditPopup v-if="showAddEditPopup" @closeAddEdit="closeAddEdit" :addEditData="addEditData" :categories="categories"
      :baseDrinks="baseDrinks"></AddEditPopup>
  </div>
</template>

<script>
import AddEditPopup from "./add_edit_drink_food";

export default {
  name: "barsList",
  components: {
    AddEditPopup,
  },
  data() {
    return {
      menu: [],
      openActionDrink: 0,
      showAddEditPopup: false,
      newItemData: {
        name: null,
        category_id: null,
        sub_cat_id: null,
        price: null,
        type: null,
        branch_id: null,
        description: null,
        reference_id: null,
        image: null,
        ingridiants: null,
        instructions: null,
        is_sponsored: null,
        sponsored_start_date: null,
        sponsored_end_date: null,
      },
      addEditData: {},
      pagination: {},
      pageLoading: false,
      foodPageLoading: false,
      deletingRow: null,
      deletingType: null,
    };
  },
  created() {
    this.init(1);
    console.log(231);
  },
  methods: {
    init(page, type = "") {
      if (type == "" || type == "drink") {
        this.pageLoading = true;
      }

      this.$api.get("admin/menu-list?page=" + page).then((response) => {
        this.menu = response.drinks.data;
        this.pagination = response.drinks.pagination;
        this.pageLoading = false;
      });
    },
    toggleActionMenu(id, type) {
      if (type === "drink") {
        this.openActionFood = 0;
        this.openActionDrink = this.openActionDrink === id ? 0 : id;
      } else {
        this.openActionDrink = 0;
        this.openActionFood = this.openActionFood === id ? 0 : id;
      }
    },
    addEdit(type, item = { ...this.newItemData }) {
      this.addEditData = item;
      this.addEditData.type = type;
      this.showAddEditPopup = true;
    },
    closeAddEdit(reload = false, type) {
      if (reload) {
        this.init(1, type);
      }
      this.showAddEditPopup = false;
    },
    destroy(item) {
      if (confirm("It will delete " + item.name)) {
        this.deletingRow = item.id;
        this.deletingType = item.type;
        this.$api.post("menu-delete", { id: item.id }).then(() => {
          var page = 0;
          if (item.type === "drink") {
            page = this.pagination.current_page;
          } else {
            page = this.foodPagination.current_page;
          }
          this.init(page, item.type);
        });
      }
    },
    changeSponsored(index, item) {
      var is_sponsored = item.is_sponsored == 0 ? 1 : 0;
      console.log(is_sponsored, item.is_sponsored);
      this.$api
        .post("admin/add-remove-sponsored-drink", {
          id: item.id,
          is_sponsored: is_sponsored,
        })
        .then(() => {
          this.menu[index].is_sponsored = is_sponsored;
        });
    },
  },
};
</script>