<template>
  <div>
    <h2 class="page-title">Bars</h2>
    <div class="text-right">
      <div class="relative inline-block w-80">
        <div
          class="
            absolute
            inset-y-0
            left-0
            pl-3
            flex
            items-center
            pointer-events-none
          "
        >
          <span class="text-gray-500 sm:text-sm">
            <img src="@/assets/images/search.png" alt="" />
          </span>
        </div>
        <input
          type="search"
          name=""
          id=""
          class="custom-input"
          style="padding-left: 40px"
          placeholder="Search"
          v-model="searchString"
          @blur="init(1)"
          @keyup="searchStringChange"
        />
        <div
          class="
            absolute
            inset-y-0
            right-0
            pr-3
            flex
            items-center
            cursor-pointer
          "
        >
          <span class="text-gray-500 sm:text-sm">
            <img src="@/assets/images/close.png" alt="" />
          </span>
        </div>
      </div>
      <!-- <div class="col-span-2">
        <btn class="inline-block">Export</btn>
      </div> -->
    </div>

    <table>
      <thead>
        <tr>
          <!-- <th>Logo</th> -->
          <th>Business Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Address</th>
          <th>Status</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="bar in bars"
          :key="bar.id"
          :class="{ deleting: processingRow === bar.id }"
        >
          <!-- <td class="w-20 border-0">
            <ig :src="bar.logo" class="rounded-full"></ig>
          </td> -->
          <td>{{ bar.business_name }}</td>
          <td>{{ bar.email }}</td>
          <td>{{ bar.phone }}</td>
          <td class="lg:w-20">{{ bar.state }}</td>
          <td class="capitalize">{{ bar.status }}</td>
          <td class="action" @click="changeStatus(bar)">
            <span class="text-sm text-domGray">Change status to</span> <br />
            {{ bar.status === "active" ? "Inactive" : "Active" }}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th colspan="7" class="text-right pt-3">
            <btn
              :loading="pageLoading"
              :type="'button'"
              :light="true"
              :disabled="pagination.current_page <= 1"
              @clicked="init(pagination.current_page - 1, 'drink')"
            >
              Previous
            </btn>
            <div class="mx-10 font-normal">
              Page {{ pagination.current_page }} of {{ pagination.total_pages }}
            </div>
            <btn
              :loading="pageLoading"
              :type="'button'"
              :light="true"
              :disabled="pagination.current_page >= pagination.total_pages"
              @clicked="init(pagination.current_page + 1, 'drink')"
            >
              Next
            </btn>
          </th>
        </tr>
      </tfoot>
    </table>
    <div class="mobile-tfoot">
      <div>
        <btn :type="'button'" @clicked="addEdit">
          <span class="block px-10">Add New bar</span>
        </btn>
      </div>
      <div class="pb-5">
        <btn
          :loading="pageLoading"
          :type="'button'"
          :light="true"
          :disabled="pagination.current_page == 1"
        >
          Previous
        </btn>
        <div class="mx-10 font-normal">
          Page {{ pagination.current_page }} of {{ pagination.total_pages }}
        </div>
        <btn
          :loading="pageLoading"
          :type="'button'"
          :light="true"
          :disabled="pagination.current_page >= pagination.total_pages"
        >
          Next
        </btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "barsList",
  data() {
    return {
      bars: [],
      openAction: 0,
      showAddEditPopup: false,
      addEditData: {},
      pagination: {},
      pageLoading: false,
      searchString: null,
      processingRow: null,
    };
  },
  created() {
    this.init(1);
  },
  methods: {
    init(page) {
      this.pageLoading = true;

      var url = "admin/get-bar-list?page=" + page;

      if (this.searchString) {
        url += "&search=" + this.searchString;
      }

      this.$api.get(url).then((response) => {
        this.bars = response.data.data;
        this.pagination = response.data.pagination;
        this.pageLoading = false;
      });
    },
    toggleActionMeun(id) {
      this.openAction = this.openAction === id ? 0 : id;
    },
    addEdit(item) {
      this.addEditData = item;
      this.showAddEditPopup = true;
    },
    closeAddEdit(reload = false) {
      if (reload) {
        this.init(1);
      }
      this.showAddEditPopup = false;
    },
    searchStringChange(e) {
      if (e.keyCode === 13) {
        this.init(1);
      }
    },
    changeStatus(bar) {
      this.processingRow = bar.id;
      this.$api
        .post("admin/block-user", {
          user_id: bar.id,
          status: bar.status === "active" ? "inActive" : "active",
        })
        .then(() => {
          this.init(this.pagination.current_page);
          this.processingRow = false;
        });
    },
  },
};
</script>