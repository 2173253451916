<template>
  <div
    class="
      bg-white
      fixed
      inset-0
      w-full
      h-screen
      z-50
      bg-opacity-50
      overflow-x-auto
    "
  >
    <div class="bg-domWhite shadow-xl w-full md:w-1/2 mx-auto mt-24 p-5 px-10">
      <div class="font-normal flow-root">
        <span class="text-2xl float-left">{{ popupTitle }} Credit History</span>
        <span class="float-right">
          <img
            src="@/assets/images/close.png"
            alt=""
            class="cursor-pointer"
            @click="$emit('closeCreditHistory', false)"
          />
        </span>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th>Points</th>
            </tr>
          </thead>
          <tbody v-if="userCreditData.length > 0 && !loading">
            <tr v-for="user in userCreditData" :key="user.id">
              <td>{{ user.created_at }}</td>
              <td>{{ user.amount_in_usd }}</td>
              <td>{{ user.credit }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="3">Loading</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="3">No data found</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreditHistory",
  props: {
    userData: Object,
    userCreditData: Array,
    loading: Boolean
  },
  data() {
    return {
      popupTitle: null,
    };
  },
  created() {
    this.popupTitle = this.userData.first_name + " " + this.userData.last_name;
  },
};
</script>
