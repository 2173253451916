<template>
  <div>
    <h2 class="page-title">First Night Menu</h2>

    <!-- Drink Table -->
    <table>
      <thead>
        <tr>
          <th>Drinks</th>
          <th>Category</th>
          <th>Alcohol Base</th>
          <th>Price</th>
          <th>&nbsp;</th>
        </tr>
        <!-- <tr>
          <th>Drinks</th>
          <th>Category</th>
          <th>Alcohol Base</th>
          <th>Price</th>
          <th>&nbsp;</th>
        </tr> -->
      </thead>
      <tbody>
        <tr v-for="drink in drinks" :key="drink.id" :class="{
          deleting: deletingRow === drink.id && deletingType == 'drink',
        }">
          <td class="w-1/2">{{ drink.name }}</td>
          <td class="w-40">{{ drink.category }}</td>
          <td class="w-40">{{ drink.sub_category }}</td>
          <td>${{ truncateNumber(drink.price) }}</td>
          <td class="action" :class="{
            active: openActionDrink == drink.id,
            inactive: openActionDrink != drink.id,
          }" @click="toggleActionMenu(drink.id, 'drink')">
            Manage
            <ul v-show="openActionDrink === drink.id">
              <li class="p-3" @click="addEdit('drink', drink)">Edit</li>
              <li class="p-3 bg-red-400 text-white" @click="destroy(drink)">
                Delete
              </li>
            </ul>
          </td>
        </tr>
        <tr v-if="drinks.length == 0">
          <td colspan="5">The branch doesn't have any drinks on the menu.</td>
        </tr>
      </tbody>
      <tfoot>
        <th class="text-left p-3">
          <btn :type="'button'" @clicked="addEdit('drink')">
            <span class="block px-10">Add Drink</span>
          </btn>
        </th>
        <th colspan="4" class="text-right pt-3">
          <btn :loading="drinkPageLoading" :type="'button'" :light="true" :disabled="drinkPagination.current_page <= 1"
            @clicked="init(drinkPagination.current_page - 1, 'drink')">
            Previous
          </btn>
          <div class="mx-10 font-normal">
            Page {{ drinkPagination.current_page }} of
            {{ drinkPagination.total_pages }}
          </div>
          <btn :loading="drinkPageLoading" :type="'button'" :light="true" :disabled="drinkPagination.current_page >= drinkPagination.total_pages
            " @clicked="init(drinkPagination.current_page + 1, 'drink')">
            Next
          </btn>
        </th>
      </tfoot>
    </table>

    <!-- Food Table -->
    <table>
      <thead>
        <tr>
          <th>Food</th>
          <th>Category</th>
          <th>Sub Category</th>
          <th>Price</th>
          <th>&nbsp;</th>
        </tr>
        <!-- <tr>
          <th>Drinks</th>
          <th>Category</th>
          <th>Sub Category</th>
          <th>Price</th>
          <th>&nbsp;</th>
        </tr> -->
      </thead>
      <tbody>
        <tr v-for="food in foods" :key="food.id" :class="{
          deleting: deletingRow === food.id && deletingType == 'food',
        }">
          <td class="w-1/2">{{ food.name }}</td>
          <td class="w-40">{{ food.category }}</td>
          <td class="w-40">{{ food.sub_category }}</td>
          <td>${{ truncateNumber(food.price) }}</td>
          <!-- <td class="action inactive" @click="addEdit('food', food)">Edit</td> -->

          <td class="action" :class="{
            active: openActionFood == food.id,
            inactive: openActionFood != food.id,
          }" @click="toggleActionMenu(food.id, 'food')">
            Manage
            <ul v-show="openActionFood === food.id">
              <li class="p-3" @click="addEdit('food', food)">Edit</li>
              <li class="p-3 bg-red-400 text-white" @click="destroy(food)">
                Delete
              </li>
            </ul>
          </td>
        </tr>
        <tr v-if="foods.length == 0">
          <td colspan="5">The branch doesn't have any foods on the menu.</td>
        </tr>
      </tbody>
      <tfoot>
        <th class="text-left p-3">
          <btn :type="'button'" @clicked="addEdit('food')">
            <span class="block px-10">Add Food</span>
          </btn>
        </th>
        <th colspan="4" class="text-right pt-3">
          <btn :loading="foodPageLoading" :type="'button'" :light="true" :disabled="foodPagination.current_page <= 1"
            @clicked="init(foodPagination.current_page - 1)">
            Previous
          </btn>
          <div class="mx-10 font-normal">
            Page {{ foodPagination.current_page }} of
            {{ foodPagination.total_pages }}
          </div>
          <btn :loading="foodPageLoading" :type="'button'" :light="true" :disabled="foodPagination.current_page >= foodPagination.total_pages
            " @clicked="init(foodPagination.current_page + 1)">
            Next
          </btn>
        </th>
      </tfoot>
    </table>

    <AddEditPopup v-if="showAddEditPopup" @closeAddEdit="closeAddEdit" :addEditData="addEditData" :categories="categories"
      :baseDrinks="baseDrinks"></AddEditPopup>
    <!-- <AddEditFood v-if="showAddEditFood" @closeAddEditFood="showAddEditFood = false"></AddEditFood> -->
  </div>
</template>

<script>
import F from "../../helper/function";
import AddEditPopup from "./add_edit_drink_food";
// import AddEditFood from "./add_edit_food";


export default {
  name: "brancheMenu",
  components: {
    AddEditPopup,
  },
  data() {
    return {
      categories: {
        drink: [],
        food: [],
      },
      drinks: [],
      foods: [],
      openActionDrink: 0,
      openActionFood: 0,
      showAddEditPopup: false,
      newItemData: {
        name: null,
        category_id: null,
        sub_cat_id: null,
        price: null,
        type: null,
        branch_id: null,
        description: null,
        reference_id: null,
        image: null,
        ingridiants: null,
        instructions: null,
        is_sponsored: null,
        sponsored_start_date: null,
        sponsored_end_date: null,
      },
      addEditData: {},
      drinkPagination: {},
      drinkPageLoading: false,
      foodPagination: {},
      foodPageLoading: false,
      deletingRow: null,
      deletingType: null,
      baseDrinks: [],
    };
  },
  created() {
    this.init(1);
  },
  methods: {
    init(page, type = "") {
      if (type == "" || type == "drink") {
        this.drinkPageLoading = true;
      }

      if (type == "" || type == "food") {
        this.foodPageLoading = true;
      }

      this.$api
        .get(
          "bar/menu-list?branch_id=" + this.$route.params.id + "&page=" + page
        )
        .then((response) => {
          if (type == "" || type == "drink") {
            this.drinks = response.drinks.data;
            this.drinkPagination = response.drinks.pagination;
          }

          if (type == "" || type == "food") {
            this.foods = response.foods.data;
            this.foodPagination = response.foods.pagination;
          }

          this.drinkPageLoading = false;
          this.foodPageLoading = false;
        });

      if (this.baseDrinks.length === 0) {
        this.$api.get("bar/get-all-base-drink").then((response) => {
          this.baseDrinks = response.data.data;
        });
      }

      if (
        this.categories.drink.length == 0 &&
        this.categories.food.length == 0
      ) {
        this.$api
          .post("bar/category-list", { type: "drink" })
          .then((response) => {
            this.categories.drink = response.data.data;
          });
        this.$api
          .post("bar/category-list", { type: "food" })
          .then((response) => {
            this.categories.food = response.data.data;
          });
      }
    },
    addEdit(type, item = { ...this.newItemData }) {
      this.addEditData = item;
      this.addEditData.type = type;
      this.showAddEditPopup = true;
    },
    closeAddEdit(reload = false, type) {
      if (reload) {
        this.init(1, type);
      }
      this.showAddEditPopup = false;
    },
    toggleActionMenu(id, type) {
      if (type === "drink") {
        this.openActionFood = 0;
        this.openActionDrink = this.openActionDrink === id ? 0 : id;
      } else {
        this.openActionDrink = 0;
        this.openActionFood = this.openActionFood === id ? 0 : id;
      }
    },
    destroy(item) {
      if (confirm("It will delete " + item.name)) {
        this.deletingRow = item.id;
        this.deletingType = item.type;
        this.$api.post("menu-delete", { id: item.id }).then(() => {
          var page = 0;
          if (item.type === "drink") {
            page = this.drinkPagination.current_page;
          } else {
            page = this.foodPagination.current_page;
          }
          this.init(page, item.type);
        });
      }
    },
    truncateNumber(value) {
      return F.truncateNumber(value, 2);
    }
  },
};
</script>