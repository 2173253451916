<template>
  <div class="
      bg-white
      fixed
      inset-0
      w-full
      h-screen
      z-50
      bg-opacity-50
      overflow-x-auto
    ">
    <div class="
        bg-domWhite
        shadow-xl
        w-full
        md:w-1/2
        xl:w-1/3
        mx-auto
        mt-24
        p-5
        px-10
      ">
      <div class="font-normal flow-root">
        <span class="text-2xl float-left">{{ popupTitle }} Send Credit</span>
        <span class="float-right">
          <img src="@/assets/images/close.png" alt="" class="cursor-pointer" @click="$emit('closeSendCredit', false)" />
        </span>
      </div>
      <!-- <div class="mt-5">
        <input placeholder="$" class="w-full h-10 border-2 pl-2" v-model="credits" type="number" />
        <p v-if="credits>0" class="pl-2 pt-2">{{credits*100}} Credits</p>
      </div> -->
      <div class="relative z-10 mt-5">
        <div class="
            absolute
            inset-y-0
            left-0
            pl-3
            flex
            items-center
            pointer-events-none
          ">
          <span class="text-gray-500 sm:text-base">$</span>
        </div>
        <input type="number" class="w-full h-10 dom-border-input" style="padding-left: 30px" v-model="credits" />
      </div>
      <div>
        <p v-if="credits > 0 && credits < dashboardData.total_credits
          ? (addClass = true)
          : (addClass = false)
          " class="pl-3 pt-1 text-left">
          {{ credits * 1000 }} Credits
        </p>
        <p v-if="credits > dashboardData.total_credits" class="pl-3 pt-1 text-left">
          Credits limit ${{ truncateNumber(dashboardData.total_credits) }}
        </p>
        <div class="mt-5 text-right">
          <btn :disabled="!addClass" :loading="loading" :class="{
            'bg-blue-500 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed':
              addClass === false,
          }" @click="sendCreditAmount()">Send</btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import F from "../../helper/function";
// import store from '../../store'

export default {
  name: "SendCredit",
  props: {
    userData: Object,
    dashboardData: Object,
  },
  data() {
    return {
      popupTitle: null,
      credits: null,
      loading: false,
      addClass: false,
      dsbl: true,
    };
  },
  created() {
    this.popupTitle = this.userData.first_name + " " + this.userData.last_name;
  },
  methods: {
    sendCreditAmount() {
      this.loading = true;
      this.$api
        .post("admin/send-user-credit", {
          user_id: this.userData.id,
          credit: this.credits,
        })
        .then((response) => {
          this.loading = false;
          this.$emit('closeSendCredit', false)
          this.$toast.info(response.message)
        });
    },
    truncateNumber(value) {
      return F.truncateNumber(value, 2);
    },
  },
};
</script>
