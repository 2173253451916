<template>
  <div>
    <h2 class="page-title">Reports</h2>

    <div class="mt-14">
      <div class="text-2xl font-bold">Credit History</div>
    </div>

    <!--  -->
    <table>
      <thead>
        <tr>
          <th>Drink</th>
          <th>Branch</th>
          <th>User</th>
          <th>Date</th>
          <!-- <th>Gratuity</th> -->
          <th>Application Fee</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in credit_history" :key="row.id">
          <td>{{ row.drink }}</td>
          <td>{{ row.branch }}</td>
          <td>{{ row.user }}</td>
          <td>{{ row.date }}</td>
          <!-- <td>${{ truncateNumber(row.gratuity) }}</td> -->
          <td>${{ truncateNumber(row.application_fee) }}</td>
        </tr>
      </tbody>
      <!-- <tfoot>
                <tr>
                    <th colspan="3" class="text-left p-3">
                        <btn :type="'button'">
                            <span class="block px-10" @click="addEdit({...newItemData})">Add New Branch</span>
                        </btn>
                    </th>
                    <th colspan="5" class="text-right pt-3">
                        <btn :loading="pageLoading" :type="'button'" :light="true" :disabled="pagination.current_page <= 1" @click="init(pagination.current_page - 1,'drink')">
                            Previous
                        </btn>
                        <div class="mx-10 font-normal">
                            Page {{ pagination.current_page }} of {{ pagination.total_pages }}
                        </div>
                        <btn :loading="pageLoading" :type="'button'" :light="true" :disabled="pagination.current_page >= pagination.total_pages" @click="init(pagination.current_page + 1,'drink')">
                            Next
                        </btn>
                    </th>
                </tr>
            </tfoot> -->
    </table>
    <!-- <div class="mobile-tfoot">
            <div>
                <btn :type="'button'" @click="addEdit">
                    <span class="block px-10">Add New Branch</span>
                </btn>
            </div>
            <div class="pb-5">
                <btn :loading="pageLoading" :type="'button'" :light="true" :disabled="pagination.current_page == 1">
                    Previous
                </btn>
                <div class="mx-10 font-normal">
                    Page {{ pagination.current_page }} of {{ pagination.total_pages }}
                </div>
                <btn :loading="pageLoading" :type="'button'" :light="true" :disabled="pagination.current_page >= pagination.total_pages">
                    Next
                </btn>
            </div>
        </div> -->

    <div class="mt-14">
      <div class="text-2xl font-bold">Payout History</div>
    </div>
  </div>
</template>

<script>
import F from "../../helper/function";

export default {
  name: "barsList",
  data() {
    return {
      credit_history: [],
      pagination: {},
      pageLoading: false,
    };
  },
  created() {
    this.init(1);
  },
  methods: {
    init(page) {
      this.pageLoading = true;
      this.$api
        .get("admin/get-payment-history?page=" + page)
        .then((response) => {
          this.credit_history = response.data.credit_history;
          // this.pagination = response.branch.pagination;
          this.pageLoading = false;
        });
    },
    toggleActionMeun(id) {
      this.openAction = this.openAction === id ? 0 : id;
    },

    truncateNumber(value) {
      return F.truncateNumber(value, 2);
    }
  },
};
</script>