<template>
    <div class="bg-white fixed inset-0 w-full h-screen z-50 bg-opacity-50 overflow-x-auto">
        <div class="bg-domWhite shadow-xl w-full md:w-1/2 mx-auto mt-24 p-5 px-10">
            <div class="text-2xl font-normal flow-root">
                <span class="float-left">
                    {{ popupTitle }}
                </span>
                <span class="float-right">
                    <img src="@/assets/images/close.png" alt="" class="cursor-pointer"  @click="$emit('closeAddEdit', false);">
                </span>
            </div>
            <Form @submit="submit" :validation-schema="validate">
                <div>
                    <div>
                        <ig :class="'w-28 h-28 rounded-full m-auto border'" :src="category.image"></ig>
                        <input type="file" ref="image" class="hidden" accept="image/*" @change="onFileChange" />
                        <div class="mb-3 mt-2 cursor-pointer text-lg text-center" @click="$refs.image.click()">
                            Change Image
                        </div>
                        <div v-if="logoError" class="input-error  text-center">
                            {{ logoError }}
                        </div>
                    </div>
                </div>
                <div class="input-box">
                    <label for="email-address" class="text-xl">Name</label>
                    <div>
                        <Field name="name" type="text" required class="dom-border-input" v-model="category.name" />
                        <ErrorMessage name="name" class="input-error" />
                    </div>
                </div>
                <!-- <div class="input-box">
                    <label for="email-address" class="text-xl">Category</label>
                    <div>
                        <Field name="category_id" required class="dom-border-input" as="select" v-model="category.category_id" @change="getSubCategories">
                            <option disabled :value="null">Select</option>
                            <template v-for="category in categories">
                                <option v-if="category.type == category.type" :value="category.id" :key="category.id">{{ category.name }}</option>
                            </template>
                        </Field>
                        <ErrorMessage name="category_id" class="input-error" />
                    </div>
                </div>
                <div class="input-box">
                    <label for="email-address" class="text-xl">{{ category.type == 'drink' ? 'Alcoholic Base' : 'Subcategory' }}</label>
                    <div>
                        <Field name="sub_cat_id" required class="dom-border-input" as="select" v-model="category.sub_cat_id">
                            <option disabled :value="null">Select</option>
                            <option v-for="(category,id) in subCategories" :key="id" :value="id">{{ category }}</option>
                        </Field>
                        <ErrorMessage name="sub_cat_id" class="input-error" />
                    </div>
                </div> --> 
                <div class="my-8 text-center">
                    <btn :loading="loadingSaveChanges" :black="true" :type="'submit'">
                        <span class="px-4">{{ popupButtonText }}</span>
                    </btn>
                </div>
            </Form>
        </div>
        <div class="fixed z-50 h-full w-full inset-0 bg-black bg-opacity-75" v-if="showCropper">
            <div class="w-full md:w-8/12 lg:w-6/12 mx-auto relative mt-20">
                <vue-cropper
                    ref="cropper"
                    :src="imagePreview"
                    :aspect-ratio="1 / 1"
                >
                </vue-cropper>
                <div class="mt-8 mb-4 text-right">
                    <btn class="mr-2" @clicked="closeCropper()" :light="true">Cancel</btn>
                    <btn @clicked="cropImage()">Select</btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {object, string} from 'yup'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import F from "../../helper/function";

export default {
    name: 'AddEditCategory',
    components:{
        VueCropper
    },
    props: {
        addEditData: Object,
        categories: Array
    },
    data() {
        return {
            validate: object({
                name: string().min(2,'Please enter name').required('Please enter name').nullable(),
            }),
            category: {},
            loadingSaveChanges: false,
            popupTitle: null,
            popupButtonText: null,
            imagePreview: null,
            showCropper: false,
            logoError: false,
            subCategories: [],
            imageAdded: false,
        }
    },
    created() {
        this.category = {...this.addEditData}
        var action = this.category.id ? "Update" : "Add";
        var itemType = this.category.type == 'food' ? "Food Category" : "Drink Category";
        this.popupTitle = action + " " + itemType
        this.popupButtonText = action + " " + itemType
        // console.log(this.addEditData);
        // console.log(this.category);
    },
    methods: {
        onFileChange(e) {
            this.logoError = false
            const file = e.target.files[0];
            this.imageToBase64(file);
        },
        imageToBase64(file) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.imagePreview = reader.result;
                this.showCropper = true;
            };
        },
        deletePreview() {
            this.imagePreview = null;
        },
        closeCropper(){
            this.showCropper = false;
            this.imagePreview = null;
            this.$refs.image.value = "";
        },
        cropImage() {
            // get image data for post processing, e.g. upload or setting image src
            this.category.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
            this.showCropper = false;
            this.$refs.image.value = "";
            this.imageAdded = true;
        },
        getSubCategories(){
            if(this.category.category_id){
                this.$api.post('bar/sub-category-by-category',{
                    id: this.category.category_id
                }).then((response) => {
                    this.subCategories = response.data;
                })
            }
        },
        submit(){
            this.loadingSaveChanges = true;
            var formData = new FormData();
            // this.category.branch_id = this.$route.params.id
            for ( var key in this.category ) {
                if(key != 'image'){
                    formData.append(key, this.category[key]);
                }
            }

            if(this.category.image && this.imageAdded){
                // const file = new File([this.category.image], "menu_item_image.png");
                // formData.append('image', file)
                formData.append("image", F.dataURIToBlob(this.category.image), "menu_item_image.png");
            }else{
                delete formData['image']
            }

            var url = 'admin/add-category';
            if(this.category.id){
                url = "admin/category-update";
            }
            this.$api.post(url,formData).then((response)=> {
                this.$toast.info(response.message)
                this.loadingSaveChanges = false;
                this.$emit('closeAddEdit',true,this.category.type);
            }).catch(()=>{
                this.loadingSaveChanges = false;
            })
            
        }
    },
}
</script>