<template>
    <div>
        <h2 class="page-title">Redemptions</h2>

        <!-- Active [Start] -->
        <div class="mt-14">
            <div class="text-2xl font-bold">Active</div>
        </div>
        <table>
            <thead>
                <tr>
                    <th>Verification</th>
                    <th>Date</th>
                    <!-- <th>Time</th> -->
                    <th>Recipient Name</th>
                    <th>Item Name</th>
                    <th>Branch Name</th>
                    <!-- <th>Item Price</th> -->
                    <!-- <th>Gratuity</th> -->
                    <!-- <th>Total</th> -->
                </tr>

            </thead>
            <tbody>
                <tr v-for="act in active" :key="act.id">
                    <td>
                        <ig :src="act.verification_image" class="rounded-full w-12 h-12"></ig>
                    </td>
                    <td>{{ formattedDate(act.created_at) }}</td>
                    <!-- <td>{{ formattedTime(act.created_at) }}</td> -->
                    <td>
                        {{
                            act.recipient_user.first_name + " " + act.recipient_user.last_name
                        }}
                    </td>
                    <td>{{ act.drink.name }}</td>
                    <td>{{ act.branch.name }}</td>
                    <!-- <td>${{ truncateNumber(act.drink.price) }}</td>
                    <td>${{ truncateNumber(act.gratuity) }}</td>
                    <td>${{ truncateNumber(act.total) }}</td> -->
                    <td>
                        <button v-if="act.is_ready_to_redeem == 1" type="button" @click="sendDrinkReadyNotification(act)">
                            <svg v-if="sendingDoneNotification == act.id" aria-hidden="true"
                                class="w-6 h-6 mr-2 text-[#f3752c] animate-spin dark:text-[#f3752c] fill-[#f3752c]"
                                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="#f3752c" />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="white" />
                            </svg>
                            <img v-if="sendingDoneNotification != act.id" class="w-8" src="@/assets/images/notification.svg"
                                alt="" />
                        </button>
                        <div v-else-if="act.notification_sent == 1">
                            Sent
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th colspan="11" class="text-right pt-3">
                        <div class="pb-5">
                            <btn :loading="activePageLoading" :type="'button'" :light="true"
                                :disabled="activePagination.current_page == 1"
                                @clicked="init(activePagination.current_page - 1, 'active')">
                                Previous
                            </btn>
                            <div class="mx-10 font-normal">
                                Page {{ activePagination.current_page }} of
                                {{ activePagination.total_pages }}
                            </div>
                            <btn :loading="activePageLoading" :type="'button'" :light="true" :disabled="activePagination.current_page >= activePagination.total_pages
                                " @clicked="init(activePagination.current_page + 1, 'active')">
                                Next
                            </btn>
                        </div>
                    </th>
                </tr>
            </tfoot>
        </table>
        <!-- Active [End] -->

        <!-- Redeemed [Start] -->
        <div class="mt-14">
            <div class="text-2xl font-bold">Redeemed</div>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-3 mt-8 filter w-full">
            <div class="lg:max-w-sm">
                <SelectWithSearch :options="branches" :showOptions="showOptions" :searchString="branchName"
                    @optionClicked="branchSelected" @toggleOptions="showOptions = showOptions ? false : true">
                    <Field name="name" type="text" required class="select-search-input" v-model="branchName"
                        @keyup="changeBranchName" :placeholder="'Branch name'" />
                </SelectWithSearch>
            </div>
            <div class="grid grid-cols-2">
                <datepicker v-model="fromDate" :clearable="false" :placeholder="'From date'" />
                <datepicker v-model="toDate" :clearable="false" :lowerLimit="fromDate" :placeholder="'To date'" />
            </div>
            <div class="grid grid-cols-5">
                <div class="relative inline-block col-span-3">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span class="text-gray-500 sm:text-sm">
                            <img src="@/assets/images/search.png" alt="" />
                        </span>
                    </div>
                    <input type="search" name="" id="" class="custom-input" style="padding-left: 40px" placeholder="Search"
                        v-model="searchString" @blur="init(1)" @keyup="searchStringChange" />
                    <div class=" absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                        <span class="text-gray-500 sm:text-sm">
                            <img src="@/assets/images/close.png" alt="" />
                        </span>
                    </div>
                </div>
                <div class="col-span-2">
                    <btn class="inline-block" @click="exportFile()">Export</btn>
                </div>
            </div>
        </div>
        <table>
            <thead>
                <tr>
                    <th>Date</th>
                    <!-- <th>Time</th> -->
                    <th>Recipient Name</th>
                    <th>Item Name</th>
                    <th>Branch Name</th>
                    <!-- <th>Item Price</th> -->
                    <!-- <th>Gratuity</th> -->
                    <!-- <th>Total</th> -->
                </tr>
            </thead>
            <tbody>
                <tr v-for="redeemed in redemptions" :key="redeemed.id">
                    <td>{{ formattedDate(redeemed.created_at) }}</td>
                    <!-- <td>{{ formattedTime(redeemed.created_at) }}</td> -->
                    <td>
                        {{
                            redeemed.recipient_user.first_name +
                            " " +
                            redeemed.recipient_user.last_name
                        }}
                    </td>
                    <td>{{ redeemed.drink.name }}</td>
                    <td>{{ redeemed.branch.name }}</td>
                    <!-- <td>${{ truncateNumber(redeemed.drink.price) }}</td>
                    <td>${{ truncateNumber(redeemed.gratuity) }}</td>
                    <td>${{ truncateNumber(redeemed.total) }}</td> -->
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th colspan="8" class="text-right pt-3">
                        <div class="pb-5">
                            <btn :loading="redemptionPageLoading" :type="'button'" :light="true"
                                :disabled="redemptionsPagination.current_page == 1" @clicked="
                                    init(redemptionsPagination.current_page - 1, 'redeemed')
                                    ">
                                Previous
                            </btn>
                            <div class="mx-10 font-normal">
                                Page {{ redemptionsPagination.current_page }} of
                                {{ redemptionsPagination.total_pages }}
                            </div>
                            <btn :loading="redemptionPageLoading" :type="'button'" :light="true" :disabled="redemptionsPagination.current_page >=
                                redemptionsPagination.total_pages
                                " @clicked="
        init(redemptionsPagination.current_page + 1, 'redeemed')
        ">
                                Next
                            </btn>
                        </div>
                    </th>
                </tr>
            </tfoot>
        </table>
        <!-- Redeemed [Start] -->
    </div>
</template>

<script>
import { mapState } from "vuex";
import F from "../../helper/function";
import Datepicker from "vue3-datepicker";
import Pusher from "pusher-js/worker";

window.Pusher = Pusher;

export default {
    name: "purchasesList",
    components: {
        Datepicker,
    },
    data() {
        return {
            redemptions: [],
            redemptionsPagination: {},
            redemptionPageLoading: false,
            active: [],
            activePagination: {},
            activePageLoading: false,
            fromDate: null,
            toDate: null,
            branch: null,
            showOptions: false,
            branchName: null,
            searchString: null,
            export: false,
            sendingDoneNotification: null,
            subscribed: false
        };
    },
    computed: {
        ...mapState({
            user: state => state.User.user,
            branches: (state) => state.Branches.branches,
        }),
    },
    watch: {
        fromDate: function () {
            this.init(1);
        },
        toDate: function () {
            this.init(1);
        },
    },
    beforeMount() {
        this.subscribe()
    },
    created() {
        this.init(1);
    },
    methods: {
        init(page, type = "") {
            if (type == "" || type == "active") {
                this.activePageLoading = true;
                this.$api
                    .get("bar/get-branch-active-redemption?page=" + page)
                    .then((response) => {
                        this.active = response.active_redemption.data;
                        this.activePagination = response.active_redemption.pagination;
                        this.activePageLoading = false;
                    });
            }

            if (type == "" || type == "redeemed") {
                this.redemptionPageLoading = true;

                var url = "bar/get-redeemed-list?page=" + page;
                if (this.branch) {
                    url += "&branch_id=" + this.branch.id;
                }
                if (this.fromDate) {
                    let formattedDate =
                        this.fromDate.getFullYear() +
                        "-" +
                        (this.fromDate.getMonth() + 1) +
                        "-" +
                        this.fromDate.getDate();
                    url += "&from_date=" + formattedDate;
                }
                if (this.toDate) {
                    let formattedDate =
                        this.toDate.getFullYear() +
                        "-" +
                        (this.toDate.getMonth() + 1) +
                        "-" +
                        this.toDate.getDate();
                    url += "&to_date=" + formattedDate;
                }
                if (this.searchString) {
                    url += "&search=" + this.searchString;
                }

                if (this.export) {
                    url += "&export=1";
                }

                this.$api.post(url).then((response) => {
                    if (this.export) {
                        this.export = false;
                        const blob = new Blob([response]);
                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(blob);
                        link.download = "file.csv";
                        link.click();
                        URL.revokeObjectURL(link.href);
                    } else {
                        this.redemptions = response.redeemed_list.data;
                        this.redemptionsPagination = response.redeemed_list.pagination;
                        this.redemptionPageLoading = false;
                    }
                });
            }
        },
        branchSelected(branch) {
            this.showOptions = false;
            this.branchName = branch.name;
            this.branch = branch;
            this.init(1, "redeemed");
        },
        changeBranchName() {
            if (this.branchName.length > 2) {
                this.showOptions = true;
            } else {
                if (this.branchName.length === 0) {
                    this.branch = null;
                    this.init(1, "redeemed");
                }
                this.showOptions = false;
            }
        },
        formattedDate(date) {
            let formattedDate = new Date(Date.parse(date));
            return (
                formattedDate.getMonth() +
                1 +
                "/" +
                formattedDate.getDate() +
                "/" +
                formattedDate.getFullYear()
            );
        },
        formattedTime(date) {
            let formattedDate = new Date(Date.parse(date));
            let AMPM = formattedDate.getHours() >= 12 ? 'PM' : 'AM';
            return String(formattedDate.getHours()).padStart(2, '0') + ":" + String(formattedDate.getMinutes()).padStart(2, '0') + " " + AMPM;
        },
        searchStringChange(e) {
            if (e.keyCode === 13) {
                this.init(1, "redeemed");
            }
        },
        exportFile() {
            this.export = true;
            this.init(1);
        },
        truncateNumber(value) {
            return F.truncateNumber(value, 2);
        },
        sendDrinkReadyNotification(act) {
            this.sendingDoneNotification = act.id;
            this.$api
                .post("bar/send-drink-ready-notificaiton/" + act.id)
                .then((response) => {
                    console.log(response);
                    this.$toast.info(response.message);
                    if (response.status) {
                        act.notification_sent = 1;
                        this.sendingDoneNotification = null;
                    } else {
                        this.sendingDoneNotification = null;
                    }
                }).catch(() => {
                    this.sendingDoneNotification = null;
                });
        },
        subscribe() {
            if (this.user && this.subscribed == false) {
                this.subscribed = true;

                var pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
                    authEndpoint: process.env.VUE_APP_BROADCAST_AUTH_URL,
                    auth: { 
                        headers: {
                            Accept: 'application/json',
                            Authorization: 'Bearer ' + this.user.token,
                        },
                    },
                    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
                    disableStats: true,
                    forceTLS: process.env.VUE_APP_PUSHER_APP_FORCETLS,
                });

                // if(pusher.channels.channels['private-DOM.bar.4'])
                var channel = pusher.subscribe("private-DOM.bar." + this.user.id);

                channel.bind("App\\Events\\DrinkRequestUpdate", (e) => {
                    console.log(e);
                    this.init(1)
                });
            }
        }

    },
};
</script>