<template>
  <div>
    <h2 class="page-title">Settings</h2>
    <table>
      <tbody>
        <tr v-for="(row, index) in settings" :key="index">
          <td class="w-80">
            {{ label(row.key) }}
          </td>
          <td>
            <div v-if="row.key === 'APNS'">
              <select
                v-model="settings[index].value"
                class="px-3 py-2 w-80 dom-border-input"
              >
                <option value="0">Sandbox</option>
                <option value="1">Live</option>
              </select>
            </div>
            <div v-if="row.key === 'application_fee'">
              <!-- <input type="number" v-model="settings[index].value" class="px-3 py-2 w-80 border"> -->

              <div class="relative z-10">
                <div
                  class="
                    absolute
                    inset-y-0
                    left-0
                    pl-3
                    flex
                    items-center
                    pointer-events-none
                  "
                >
                  <span class="text-gray-500 sm:text-sm"> % </span>
                </div>
                <Field
                  name="price"
                  type="number"
                  required
                  class="dom-border-input"
                  style="padding-left: 30px"
                  v-model="settings[index].value"
                />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3" class="text-right">
            <btn :type="'button'" @clicked="update()" :loading="pageLoading">
              <span class="block px-10">Update</span>
            </btn>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  name: "barsList",
  data() {
    return {
      settings: [],
      pageLoading: false,
      labels: {
        APNS: "APNS",
        application_fee: "Application fees",
      },
    };
  },
  created() {
    this.init(1);
  },
  methods: {
    label(key) {
      return this.labels[key];
    },
    init() {
      this.pageLoading = true;
      this.$api.get("admin/setting-list").then((response) => {
        this.settings = response.data;
        this.pageLoading = false;
      });
    },
    update() {
      this.pageLoading = true;
      var postKeys = [];
      var postValues = [];
      var count = 0;
      for (const value of Object.values(this.settings)) {
        postKeys[count] = value.key
        postValues[count] = value.value
        count++;
      }

      this.$api
        .post("admin/update-setting", {
          key: postKeys,
          value: postValues,
        })
        .then((response) => {
          this.pageLoading = false;
          this.$toast.info(response.message)
        });
    },
  },
};
</script>