<template>
  <div>
    <h2 class="page-title">Purchases</h2>
    <div class="
        grid grid-cols-1 grid-cols-2
        lg:grid-cols-3
        gap-x-10
        mt-4
        lg:mt-14
        filter
      ">
      <div>
        <SelectWithSearch :options="branches" :showOptions="showOptions" :searchString="branchName"
          @optionClicked="branchSelected" @toggleOptions="showOptions = showOptions ? false : true">
          <Field name="name" type="text" required class="select-search-input" v-model="branchName"
            @keyup="changeBranchName" :placeholder="'Branch name'" />
        </SelectWithSearch>
      </div>
      <div class="grid grid-cols-2 gap-x-2">
        <datepicker v-model="fromDate" :clearable="false" :placeholder="'From date'" />
        <datepicker v-model="toDate" :clearable="false" :lowerLimit="fromDate" :placeholder="'To date'" />
      </div>
      <div class="
          col-span-1
          md:col-span-2
          lg:col-span-1
          mt-3
          lg:mt-0
          grid grid-cols-5
          gap-x-10
        ">
        <div class="relative inline-block col-span-3">
          <div class="
              absolute
              inset-y-0
              left-0
              pl-3
              flex
              items-center
              pointer-events-none
            ">
            <span class="text-gray-500 sm:text-sm">
              <img src="@/assets/images/search.png" alt="" />
            </span>
          </div>
          <input type="search" name="" id="" class="custom-input" style="padding-left: 40px" placeholder="Search"
            v-model="searchString" @blur="init(1)" @keyup="searchStringChange" />
          <div class="
              absolute
              inset-y-0
              right-0
              pr-3
              flex
              items-center
              cursor-pointer
            ">
            <span class="text-gray-500 sm:text-sm">
              <img src="@/assets/images/close.png" alt="" />
            </span>
          </div>
        </div>
        <div class="col-span-2">
          <btn class="inline-block" @click="exportFile()">Export</btn>
        </div>
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Time</th>
          <th>Username</th>
          <!-- <th>Phone</th>
          <th>Email</th> -->
          <th>Recipient Name</th>
          <th>Branch Name</th>
          <th>Item Name</th>
          <th>Item Price</th>
          <th>Gratuity</th>
          <th>Total</th>
        </tr>
        <!-- <tr>
          <th>Date</th>
          <th>Time</th>
          <th>Username</th>
          <th>Phone</th>
          <th>Email</th>
          <th>Recipient Name</th>
          <th>Branch Name</th>
          <th>Item Name</th>
          <th>Item Price</th>
          <th>Gratuity</th>
          <th>Total</th>
        </tr> -->
      </thead>
      <tbody>
        <tr v-for="purchas in purchases" :key="purchas.id">
          <td>{{ formattedDate(purchas.created_at) }}</td>
          <td>{{ formattedTime(purchas.created_at) }}</td>
          <td>{{ purchas.user.first_name }}</td>
          <!-- <td>{{ purchas.user.phone }}</td>
          <td>{{ purchas.user.email }}</td> -->
          <td>{{ purchas.recipient_user.first_name }}</td>
          <td>{{ purchas.branch.name }}</td>
          <td>{{ purchas.drink.name }}</td>
          <td>${{ truncateNumber(purchas.drink.price) }}</td>
          <td>${{ truncateNumber(purchas.gratuity) }}</td>
          <td>${{ truncateNumber(purchas.total) }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th colspan="11" class="text-right pt-3">
            <div class="pb-5">
              <btn :loading="pageLoading" :type="'button'" :light="true" :disabled="pagination.current_page == 1"
                @clicked="init(pagination.current_page - 1)">
                Previous
              </btn>
              <div class="mx-10 font-normal">
                Page {{ pagination.current_page }} of
                {{ pagination.total_pages }}
              </div>
              <btn :loading="pageLoading" :type="'button'" :light="true"
                :disabled="pagination.current_page >= pagination.total_pages"
                @clicked="init(pagination.current_page + 1)">
                Next
              </btn>
            </div>
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import SimpleTypeahead from "vue3-simple-typeahead";
// import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";
import F from "../../helper/function";
import Datepicker from "vue3-datepicker";

export default {
  name: "purchasesList",
  components: {
    // SimpleTypeahead,
    Datepicker,
  },
  data() {
    return {
      purchases: [],
      pagination: {},
      pageLoading: false,
      fromDate: null,
      toDate: null,
      branch: null,
      showOptions: false,
      branchName: null,
      searchString: null,
      export: false,
    };
  },
  computed: {
    ...mapState({
      branches: (state) => state.Branches.branches,
    }),
  },
  watch: {
    fromDate: function () {
      this.init(1);
    },
    toDate: function () {
      this.init(1);
    },
  },
  created() {
    this.init(1);
  },
  methods: {
    init(page) {
      this.pageLoading = true;
      var url = "bar/get-redeemed-list?page=" + page;
      if (this.branch) {
        url += "&branch_id=" + this.branch.id;
      }
      if (this.fromDate) {
        let formattedDate =
          this.fromDate.getFullYear() +
          "-" +
          (this.fromDate.getMonth() + 1) +
          "-" +
          this.fromDate.getDate();
        url += "&from_date=" + formattedDate;
      }
      if (this.toDate) {
        let formattedDate =
          this.toDate.getFullYear() +
          "-" +
          (this.toDate.getMonth() + 1) +
          "-" +
          this.toDate.getDate();
        url += "&to_date=" + formattedDate;
      }
      if (this.searchString) {
        url += "&search=" + this.searchString;
      }
      if (this.export) {
        url += "&export=1";
      }
      this.$api.post(url).then((response) => {
        if (this.export) {
          this.export = false;
          const blob = new Blob([response]);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "file.csv";
          link.click();
          URL.revokeObjectURL(link.href);
        } else {
          this.purchases = response.redeemed_list.data;
          this.pagination = response.redeemed_list.pagination;
          this.pageLoading = false;
        }
      });
    },
    branchSelected(branch) {
      this.showOptions = false;
      this.branchName = branch.name;
      this.branch = branch;
      this.init(1);
    },
    changeBranchName() {
      if (this.branchName.length > 2) {
        this.showOptions = true;
      } else {
        if (this.branchName.length === 0) {
          this.branch = null;
          this.init(1);
        }
        this.showOptions = false;
      }
    },
    formattedDate(date) {
      let formattedDate = new Date(Date.parse(date));
      return (
        formattedDate.getMonth() +
        1 +
        "/" +
        formattedDate.getDate() +
        "/" +
        formattedDate.getFullYear()
      );
    },
    formattedTime(date) {
      let formattedDate = new Date(Date.parse(date));
      let AMPM = formattedDate.getHours() >= 12 ? 'PM' : 'AM';
      return String(formattedDate.getHours()).padStart(2, '0') + ":" + String(formattedDate.getMinutes()).padStart(2, '0') + " " + AMPM;
    },
    getAMorPM(date) {
      const formattedDate = new Date(Date.parse(date));
      const hours = formattedDate.getHours();

      return hours >= 12 ? 'PM' : 'AM';
    },
    searchStringChange(e) {
      if (e.keyCode === 13) {
        this.init(1);
      }
    },
    exportFile() {
      this.export = true;
      this.init(1);
    },
    truncateNumber(value) {
      return F.truncateNumber(value, 2);
    }
  },
};
</script>