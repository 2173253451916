<template>
  <div>
    <h2 class="page-title">Branches</h2>
    <table>
      <thead>
        <tr>
          <th>
            <span class="sm:h-10 sm:w-10 lg:h-auto lg:w-auto block">Logo</span>
          </th>
          <th>Name</th>
          <th>Address</th>
          <th>City</th>
          <th>State</th>
          <th>Zipcode</th>
          <th>Unit#</th>
          <th>&nbsp;</th>
        </tr>
        <!-- <tr>
          <th>
            <span class="sm:h-10 sm:w-10 lg:h-auto lg:w-auto block">Logo</span>
          </th>
          <th>Name</th>
          <th>Address</th>
          <th>City</th>
          <th>State</th>
          <th>Zipcode</th>
          <th>Unit#</th>
          <th>&nbsp;</th>
        </tr> -->
      </thead>
      <tbody>
        <tr v-for="branch in branches" :key="branch.id">
          <td class="w-20 border-0">
            <ig :src="branch.logo"></ig>
          </td>
          <td>{{ branch.name }}</td>
          <td>{{ branch.address }}</td>
          <td>{{ branch.city }}</td>
          <td class="lg:w-20">{{ branch.state }}</td>
          <td>{{ branch.zipcode }}</td>
          <td class="lg:w-20">{{ branch.unit }}</td>
          <td
            class="action"
            :class="{
              active: openAction == branch.id,
              inactive: openAction != branch.id,
            }"
            @click="toggleActionMeun(branch.id)"
          >
            Manage
            <ul v-show="openAction === branch.id">
              <li class="p-3" @click="addEdit(branch)">Address</li>
              <li class="p-3">
                <router-link
                  :to="{ name: 'BrancheMenu', params: { id: branch.id } }"
                >
                  Menu
                </router-link>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th colspan="3" class="text-left p-3">
            <btn :type="'button'">
              <span class="block px-10" @click="addEdit({ ...newItemData })"
                >Add New Branch</span
              >
            </btn>
          </th>
          <th colspan="5" class="text-right pt-3">
            <btn
              :loading="pageLoading"
              :type="'button'"
              :light="true"
              :disabled="pagination.current_page <= 1"
              @clicked="init(pagination.current_page - 1, 'drink')"
            >
              Previous
            </btn>
            <div class="mx-10 font-normal">
              Page {{ pagination.current_page }} of {{ pagination.total_pages }}
            </div>
            <btn
              :loading="pageLoading"
              :type="'button'"
              :light="true"
              :disabled="pagination.current_page >= pagination.total_pages"
              @clicked="init(pagination.current_page + 1, 'drink')"
            >
              Next
            </btn>
          </th>
        </tr>
      </tfoot>
    </table>
    <div class="mobile-tfoot">
      <div>
        <btn :type="'button'" @clicked="addEdit">
          <span class="block px-10">Add New Branch</span>
        </btn>
      </div>
      <div class="pb-5">
        <btn
          :loading="pageLoading"
          :type="'button'"
          :light="true"
          :disabled="pagination.current_page == 1"
        >
          Previous
        </btn>
        <div class="mx-10 font-normal">
          Page {{ pagination.current_page }} of {{ pagination.total_pages }}
        </div>
        <btn
          :loading="pageLoading"
          :type="'button'"
          :light="true"
          :disabled="pagination.current_page >= pagination.total_pages"
        >
          Next
        </btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "barsList",
  data() {
    return {
      branches: [],
      openAction: 0,
      showAddEditPopup: false,
      newItemData: {
        name: null,
        address: null,
        city: null,
        state: null,
        zipcode: null,
        unit: null,
        image: null,
      },
      addEditData: {},
      pagination: {},
      pageLoading: false,
    };
  },
  created() {
    // this.init(1)
  },
  methods: {
    init(page) {
      this.pageLoading = true;
      this.$api.get("bar/branch-list?page=" + page).then((response) => {
        this.branches = response.branch.data;
        this.pagination = response.branch.pagination;
        this.pageLoading = false;
      });
    },
    toggleActionMeun(id) {
      this.openAction = this.openAction === id ? 0 : id;
    },
    addEdit(item) {
      this.addEditData = item;
      this.showAddEditPopup = true;
    },
    closeAddEdit(reload = false) {
      if (reload) {
        this.init(1);
      }
      this.showAddEditPopup = false;
    },
  },
};
</script>