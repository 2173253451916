<template>
  <div
    class="
      bg-white
      fixed
      inset-0
      w-full
      h-screen
      z-50
      bg-opacity-50
      overflow-x-auto
    "
  >
    <div
      class="
        bg-domWhite
        shadow-xl
        w-full
        md:w-1/2
        xl:w-1/3
        mx-auto
        mt-24
        p-5
        px-10
      "
    >
      <div class="text-2xl font-normal flow-root">
        <span class="float-left">
          {{ popupTitle }}
        </span>
        <span class="float-right">
          <img
            src="@/assets/images/close.png"
            alt=""
            class="cursor-pointer"
            @click="$emit('closeAddEdit', false)"
          />
        </span>
      </div>
      <Form @submit="submit" :validation-schema="validate">
        <div>
          <div>
            <!-- <ig :class="'w-28 h-28 rounded-full m-auto border'" :src="event.logo" v-if="event.id"></ig> -->
            <ig
              :class="'w-28 h-28 rounded-full m-auto border'"
              :src="event.image"
            ></ig>
            <input
              type="file"
              ref="image"
              class="hidden"
              accept="image/*"
              @change="onFileChange"
            />
            <div
              class="mb-3 mt-2 cursor-pointer text-lg text-center"
              @click="$refs.image.click()"
            >
              Change Event Image
            </div>
            <div v-if="logoError" class="input-error text-center">
              {{ logoError }}
            </div>
          </div>
        </div>
        <div class="input-box">
          <label for="city" class="text-xl">Title</label>
          <div>
            <Field
              name="title"
              type="text"
              required
              class="dom-border-input"
              v-model="event.title"
            />
            <ErrorMessage name="title" class="input-error" />
          </div>
        </div>
        <div class="grid grid-cols-6 gap-x-3">
          <div class="input-box col-span-4">
            <label for="city" class="text-xl">Start Date</label>
            <div class="dom-border-input-inner">
              <datepicker
                v-model="event.start_date"
                @change="changeDate('start_date')"
                :clearable="false"
                :lowerLimit="lowerLimitDate"
                :inputFormat="'MM-dd-yyyy'"
              />
              <div v-if="validation.start_date" class="input-error">
                {{ validation.start_date }}
              </div>
            </div>
          </div>
          <div class="input-box col-span-2">
            <label for="city" class="text-xl">Start Time</label>
            <div class="dom-border-input-inner">
              <VueTimepicker
                v-model="start_time"
                format="hh:mm A"
                @change="changeDate('end_date')"
                placeholder="Start Time"
                hide-clear-button
              />
              <div v-if="validation.start_time" class="input-error">
                {{ validation.start_time }}
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-6 gap-x-3">
          <div class="input-box col-span-4">
            <label for="city" class="text-xl">End Date</label>
            <div class="dom-border-input-inner">
              <datepicker
                v-model="event.end_date"
                @change="changeDate('end_date')"
                :clearable="false"
                :lowerLimit="endDatelowerLimitDate"
                :inputFormat="'MM-dd-yyyy'"
              />
              <div v-if="validation.end_date" class="input-error">
                {{ validation.end_date }}
              </div>
            </div>
          </div>
          <div class="input-box col-span-2">
            <label for="city" class="text-xl">End Time</label>
            <div class="dom-border-input-inner">
              <VueTimepicker
                v-model="end_time"
                placeholder="End Time"
                format="hh:mm A"
                @change="changeDate('end_date')"
                hide-clear-button
              />
              <div v-if="validation.end_time" class="input-error">
                {{ validation.end_time }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="sameStartEndTimeError" class="input-error">
          {{ sameStartEndTimeError }}
        </div>
        <div class="my-8 text-center">
          <btn :loading="loadingSaveChanges" :black="true" :type="'submit'">
            <span class="px-4">{{ popupButtonText }}</span>
          </btn>
        </div>
      </Form>
    </div>

    <div
      class="
        fixed
        z-50
        h-full
        w-full
        inset-0
        bg-black bg-opacity-75
        overflow-x-auto
      "
      v-if="showCropper"
    >
      <div class="w-full md:w-8/12 lg:w-6/12 mx-auto relative mt-20">
        <vue-cropper ref="cropper" :src="imagePreview" :aspect-ratio="1 / 1">
        </vue-cropper>
        <div class="mt-8 mb-4 text-right">
          <btn class="mr-2" @clicked="closeCropper()" :light="true">Cancel</btn>
          <btn @clicked="cropImage()">Select</btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { object, string } from "yup";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import Datepicker from "vue3-datepicker";
import VueTimepicker from "vue3-timepicker";
import "vue3-timepicker/dist/VueTimepicker.css";
import F from "../../helper/function";

export default {
  name: "addEditEvent",
  components: {
    VueCropper,
    Datepicker,
    VueTimepicker,
  },
  computed: {
    endDatelowerLimitDate() {
      return this.event.start_date;
    },
    endTimelowerLimitDate() {
      return this.event.start_time;
    },
  },
  watch: {
    "event.start_date": function (new_date) {
      if (new_date) {
        this.validation.start_date = false;
        this.sameStartEndTimeError = false;
      }
    },
    "start_time": function (new_date) {
      if (new_date) {
        this.validation.start_time = false;
        this.sameStartEndTimeError = false;
      }
    },
    "event.end_date": function (new_date) {
      if (new_date) {
        this.validation.end_date = false;
        this.sameStartEndTimeError = false;
      }
    },
    "end_time": function (new_date) {
      if (new_date) {
        this.validation.end_time = false;
        this.sameStartEndTimeError = false;
      }
    },
  },
  props: {
    addEditEventData: Object,
  },
  data() {
    return {
      validate: object({
        title: string()
          .min(2, "Please enter event title")
          .required("Please enter event title")
          .nullable(),
        // is_sponsored: string().min(2,'Please select').required('Please select').nullable(),
      }),
      event: {},
      loadingSaveChanges: false,
      imagePreview: null,
      showCropper: false,
      logoError: false,
      popupTitle: "",
      selected: new Date(),
      lowerLimitDate: new Date(),
      newImageSelected: false,
      validation: {
        start_date: false,
        start_time: false,
        end_date: false,
        end_time: false,
      },
      sameStartEndTimeError: false,
      start_time: false,
      end_time: false,
    };
  },
  created() {
    this.event = { ...this.addEditEventData };
    var action = this.event.id ? "Update" : "Add";
    var itemType = "Event";
    this.popupTitle = action + " " + itemType;
    this.popupButtonText = action + " " + itemType;
    this.start_time = this.event.start_time;
    this.end_time = this.event.end_time;
  },
  methods: {
    onFileChange(e) {
      this.logoError = false;
      const file = e.target.files[0];
      this.imageToBase64(file);
    },
    imageToBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imagePreview = reader.result;
        this.showCropper = true;
      };
    },
    deletePreview() {
      this.imagePreview = null;
    },
    closeCropper() {
      this.showCropper = false;
      this.imagePreview = null;
      this.$refs.image.value = "";
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.event.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.showCropper = false;
      this.$refs.image.value = "";
      this.newImageSelected = true;
    },
    submit() {
      // console.log(this.event.start_time.getHours(),this.event.start_time.getMinutes());
      var hours;

      var start_time = false;
      if (this.start_time) {
        start_time = new Date(this.event.start_date);
        hours =
          this.start_time.A == "PM"
            ? parseInt(this.start_time.hh) + 12
            : parseInt(this.start_time.hh);

        start_time.setHours(hours);
        start_time.setMinutes(parseInt(this.start_time.mm ? this.start_time.mm : 0));
        // this.event.start_time = start_time;
      }else{
        this.sameStartEndTimeError =
          "Please select event start time.";
      }

      var end_time = false;
      if (this.end_time) {
        end_time = new Date(this.event.end_date);
        hours =
          this.end_time.A == "PM"
            ? parseInt(this.end_time.hh) + 12
            : parseInt(this.end_time.hh);
        end_time.setHours(hours);
        end_time.setMinutes(parseInt(this.end_time.mm ? this.end_time.mm : 0));
      }else{
        this.sameStartEndTimeError =
          "Please select event end time.";
      }
      // this.event.end_time = end_time;

      var startTime = parseInt((("0" + start_time.getHours()).slice(-2)) +  (('0' +start_time.getMinutes()).slice(-2)));
      var endTime = parseInt((("0" + end_time.getHours()).slice(-2)) + (('0' + end_time.getMinutes()).slice(-2)));
      var currentDate = new Date();
      
      console.log(this.event.start_date.getTime() ,this.event.end_date.getTime())

      if (
        this.event.start_date.getTime() === this.event.end_date.getTime() &&
        startTime >= endTime
      ) {
        this.sameStartEndTimeError =
          "Event end time should be greater than start time.";
        console.log(this.sameStartEndTimeError);
      } else if (currentDate.getTime() >= end_time.getTime()) {
        this.sameStartEndTimeError = "Event end time should be future time";
      } else {
        this.validation.start_date = false;
        if (!this.event.start_date) {
          this.validation.start_date = "Please enter start date";
        }
        this.validation.start_time = false;
        if (!start_time) {
          this.validation.start_time = "Please enter start time";
        }
        this.validation.end_date = false;
        if (!this.event.end_date) {
          this.validation.end_date = "Please enter start date";
        }
        this.validation.end_time = false;
        if (!end_time) {
          this.validation.end_time = "Please enter end time";
        }
        this.logoError = false;
        if (!this.event.image) {
          this.logoError = "Please upload event image";
        }
        var formData = new FormData();
        if (
          this.validation.start_date == false &&
          this.validation.start_time == false &&
          this.validation.end_date == false &&
          this.validation.end_time == false &&
          this.logoError == false
        ) {
          for (var key in this.event) {
            if (
              key != "image" &&
              key != "start_date" &&
              key != "start_time" &&
              key != "end_date" &&
              key != "end_time"
            ) {
              formData.append(key, this.event[key]);
            }
          }

          this.event.end_time = end_time;
          this.event.start_time = start_time;

          var startDate =
            this.event.start_date.getFullYear() +
            "-" +
            ("0" + (this.event.start_date.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + this.event.start_date.getDate()).slice(-2);
          startTime =
            this.event.start_time.getHours() +
            ":" +
            this.event.start_time.getMinutes();
          var endDate =
            this.event.end_date.getFullYear() +
            "-" +
            ("0" + (this.event.end_date.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + this.event.end_date.getDate()).slice(-2);
          endTime =
            this.event.end_time.getHours() +
            ":" +
            this.event.end_time.getMinutes();
          formData.append("start_date", startDate + " " + startTime);
          formData.append("end_date", endDate + " " + endTime);
          if (this.newImageSelected) {
            formData.append(
              "image",
              F.dataURIToBlob(this.event.image),
              "event_image.png"
            );
            this.loadingSaveChanges = true;
          }
          var url = "bar/add-event";
          if (this.event.id) {
            url = "bar/event-update";
          }
          this.$api
            .post(url, formData)
            .then((response) => {
              this.$toast.info(response.message);
              this.loadingSaveChanges = false;
              this.$emit("closeAddEdit", true);
            })
            .catch(() => {
              this.loadingSaveChanges = false;
            });
        }
      }
    },
    changeDate(field) {
      field;
      // console.log(field);
    },
    setStartTimeHours(hour) {
      this.start_time_hour = hour;
    },
    setStartTimeMinutes(minutes) {
      this.start_time_minutes = minutes;
    },
    setEndTimeHours(hour) {
      this.end_time_hour = hour;
    },
    setEndTimeMinutes(minutes) {
      this.end_time_minutes = minutes;
    },
  },
};
</script>