<template>
    <div>
        <h2 class="page-title">Bank Account</h2>
    </div>
</template>

<script>
export default {
    name: 'BankAccount',
}
</script>