<template>
  <div>
    <h2 class="page-title">Users</h2>
    <div class="text-right">
      <div class="relative inline-block w-80">
        <div
          class="
            absolute
            inset-y-0
            left-0
            pl-3
            flex
            items-center
            pointer-events-none
          "
        >
          <span class="text-gray-500 sm:text-sm">
            <img src="@/assets/images/search.png" alt="" />
          </span>
        </div>
        <input
          type="search"
          name=""
          id=""
          class="custom-input"
          style="padding-left: 40px"
          placeholder="Search"
          v-model="searchString"
          @blur="init(1)"
          @keyup="searchStringChange"
        />
        <div
          class="
            absolute
            inset-y-0
            right-0
            pr-3
            flex
            items-center
            cursor-pointer
          "
        >
          <span class="text-gray-500 sm:text-sm">
            <img src="@/assets/images/close.png" alt="" />
          </span>
        </div>
      </div>
      <!-- <div class="col-span-2">
        <btn class="inline-block">Export</btn>
      </div> -->
    </div>
    <table>
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Credit</th>
          <th>Status</th>
          <!-- <th>&nbsp;</th> -->
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="user in users"
          :key="user.id"
          :class="{ deleting: processingRow === user.id }"
        >
          <td class="w-20 border-0">
            <ig :src="user.profile_image" class="rounded-full h-12 w-12"></ig>
          </td>
          <td>{{ user.first_name + " " + user.last_name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.phone }}</td>
          <td>{{ user.available_credit * 1000 }}</td>
          <td>
            {{ user.status === "active" ? "Active" : "Inactive" }}
          </td>
          <td
            class="action"
            :class="{
              active: openAction == user.id,
              inactive: openAction != user.id,
            }"
            @click="toggleActionMeun(user.id)"
          >
            Manage
            <ul v-show="openAction === user.id">
              <li class="p-3" @click="changeStatus(user)">
                <span class="text-sm text-domGray">Change status to</span>
                <br />
                {{ user.status === "active" ? "Inactive" : "Active" }}
              </li>
              <li class="p-3" @click="sendCredit(user)">Send Credit</li>
              <li class="p-3" @click="creditHistory(user)">Credit History</li>
            </ul>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th colspan="5" class="text-right pt-3">
            <btn
              :loading="pageLoading"
              :type="'button'"
              :light="true"
              :disabled="pagination.current_page <= 1"
              @clicked="init(pagination.current_page - 1, 'drink')"
            >
              Previous
            </btn>
            <div class="mx-10 font-normal">
              Page {{ pagination.current_page }} of {{ pagination.total_pages }}
            </div>
            <btn
              :loading="pageLoading"
              :type="'button'"
              :light="true"
              :disabled="pagination.current_page >= pagination.total_pages"
              @clicked="init(pagination.current_page + 1, 'drink')"
            >
              Next
            </btn>
          </th>
        </tr>
      </tfoot>
    </table>
    <div class="mobile-tfoot">
      <div class="pb-5">
        <btn
          :loading="pageLoading"
          :type="'button'"
          :light="true"
          :disabled="pagination.current_page == 1"
        >
          Previous
        </btn>
        <div class="mx-10 font-normal">
          Page {{ pagination.current_page }} of {{ pagination.total_pages }}
        </div>
        <btn
          :loading="pageLoading"
          :type="'button'"
          :light="true"
          :disabled="pagination.current_page >= pagination.total_pages"
        >
          Next
        </btn>
      </div>
    </div>
    <CreditHistory
      v-if="creditDataHistory"
      @closeCreditHistory="closeCreditHistory"
      :userData="userData"
      :userCreditData="userCreditData"
      :loading="creditLoading"
    ></CreditHistory>
    <SendCredit
      v-if="sendCreditData"
      @closeSendCredit="closeSendCredit"
      :userData="userData"
      :dashboardData="dashboardData"
    ></SendCredit>
  </div>
</template>

<script>
import CreditHistory from "./credit_history.vue";
import SendCredit from "./send_credit.vue";
export default {
  name: "barsList",
  components: {
    CreditHistory,
    SendCredit,
  },
  data() {
    return {
      users: [],
      openAction: 0,
      // showAddEditPopup: false,
      pagination: {},
      pageLoading: false,
      searchString: null,
      processingRow: false,
      creditDataHistory: false,
      sendCreditData: false,
      userData: {},
      userCreditData: [],
      creditLoading: true,
      dashboardData: {}
    };
  },
  created() {
    this.init(1);
  },
  methods: {
    init(page) {
      this.pageLoading = true;
      var url = "admin/get-users?page=" + page;

      if (this.searchString) {
        url += "&search=" + this.searchString;
      }
      this.$api.get(url).then((response) => {
        this.users = response.data.data;
        this.pagination = response.data.pagination;
        this.pageLoading = false;
      });
    },
    toggleActionMeun(id) {
      this.openAction = this.openAction === id ? 0 : id;
    },
    addEdit(item) {
      this.addEditData = item;
      this.showAddEditPopup = true;
    },
    closeAddEdit(reload = false) {
      if (reload) {
        this.init(1);
      }
      this.showAddEditPopup = false;
    },
    searchStringChange(e) {
      if (e.keyCode === 13) {
        this.init(1);
      }
    },
    changeStatus(user) {
      this.processingRow = user.id;
      this.$api
        .post("admin/block-user", {
          user_id: user.id,
          status: user.status === "active" ? "inActive" : "active",
        })
        .then(() => {
          this.init(this.pagination.current_page);
          this.processingRow = false;
        });
    },
    creditHistory(user) {
      this.userData = user;
      this.creditDataHistory = true;
      this.creditLoading = true;
      this.$api
        .get("admin/get-credit-history-by-user?user_id=" + user.id)
        .then((response) => {
          this.userCreditData = response.data.data;
          this.creditLoading = false;
        });
    },
    closeCreditHistory(reload = false) {
      if (reload) {
        this.init(1);
      }
      this.creditLoading = true;
      (this.userCreditData = []), (this.creditDataHistory = false);
    },
    sendCredit(user) {
      this.userData = user;
      this.sendCreditData = true;
      this.$api.get("admin/dashboard").then((response) => {
        this.dashboardData = response.data;
      })
    },
    closeSendCredit(reload = false) {
      if (reload) {
        this.init(1);
      }
      this.sendCreditData = false;
    },
  },
};
</script>