<template>
    <div class="
      bg-white
      fixed
      inset-0
      w-full
      h-screen
      z-50
      bg-opacity-50
      overflow-x-auto
    ">
        <div class="
        bg-domWhite
        shadow-xl
        w-full
        md:w-1/2
        xl:w-1/3
        mx-auto
        mt-24
        p-5
        px-10
      ">
            <div class="text-2xl font-normal flow-root">
                <span class="float-left">
                    {{ popupTitle }}
                </span>
                <span class="float-right">
                    <img src="@/assets/images/close.png" alt="" class="cursor-pointer"
                        @click="$emit('closeAddEdit', false)" />
                </span>
            </div>
            <Form @submit="submit" :validation-schema="validate">
                <div>
                    <div>
                        <ig :class="'w-28 h-28 rounded-full m-auto border'" :src="branch.logo"></ig>
                        <input type="file" ref="image" class="hidden" accept="image/*" @change="onFileChange" />
                        <div class="mb-3 mt-2 cursor-pointer text-lg text-center" @click="$refs.image.click()">
                            Change Profile Picture
                        </div>
                        <div v-if="logoError" class="input-error text-center">
                            {{ logoError }}
                        </div>
                    </div>
                </div>
                <div class="input-box">
                    <label for="name" class="text-xl">Name</label>
                    <div>
                        <Field name="name" type="text" required class="dom-border-input" v-model="branch.name" />
                        <ErrorMessage name="name" class="input-error" />
                    </div>
                </div>
                <div class="input-box">
                    <label for="delivery_pickup" class="text-xl">Delivery or pickup</label>
                    <div>
                        <Field name="delivery_pickup" as="select" class="dom-border-input" required v-model="branch.delivery_pickup">
                            <option value="delivery">Delivery</option>
                            <option value="pickup">Pickup at counter</option>
                        </Field>
                        <ErrorMessage name="delivery_pickup" class="input-error" />
                    </div>
                </div>
                <div class="input-box">
                    <label for="address" class="text-xl">Address</label>
                    <div>
                        <Field name="address" type="text" required class="dom-border-input" v-model="branch.address" />
                        <ErrorMessage name="address" class="input-error" />
                    </div>
                </div>
                <div class="grid grid-cols-5 gap-x-3">
                    <div class="input-box col-span-3">
                        <label for="city" class="text-xl">City</label>
                        <div>
                            <Field name="city" type="text" required class="dom-border-input" v-model="branch.city" />
                            <ErrorMessage name="city" class="input-error" />
                        </div>
                    </div>
                    <div class="input-box col-span-2">
                        <label for="city" class="text-xl">State</label>
                        <div>
                            <Field name="state" type="text" required class="dom-border-input" v-model="branch.state" />
                            <ErrorMessage name="state" class="input-error" />
                        </div>
                    </div>
                    <div class="input-box col-span-3">
                        <label for="zipcode" class="text-xl">Zipcode</label>
                        <div>
                            <Field name="zipcode" type="text" required class="dom-border-input" v-model="branch.zipcode" />
                            <ErrorMessage name="zipcode" class="input-error" />
                        </div>
                    </div>
                    <div class="input-box col-span-2">
                        <label for="unit" class="text-xl">Unit</label>
                        <div>
                            <Field name="unit" type="text" required class="dom-border-input" v-model="branch.unit" />
                            <ErrorMessage name="unit" class="input-error" />
                        </div>
                    </div>
                </div>
                <div class="my-8 text-center">
                    <btn :loading="loadingSaveChanges" :black="true" :type="'submit'">
                        <span class="px-4">{{ popupButtonText }}</span>
                    </btn>
                </div>
            </Form>
        </div>

        <div class="
        fixed
        z-50
        h-full
        w-full
        inset-0
        bg-black bg-opacity-75
        overflow-x-auto
      " v-if="showCropper">
            <div class="w-full md:w-8/12 lg:w-6/12 mx-auto relative mt-20">
                <vue-cropper ref="cropper" :src="imagePreview" :aspect-ratio="1 / 1">
                </vue-cropper>
                <div class="mt-8 mb-4 text-right">
                    <btn class="mr-2" @clicked="closeCropper()" :light="true">Cancel</btn>
                    <btn @clicked="cropImage()">Select</btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { object, string } from "yup";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import F from "../../helper/function";

export default {
    name: "addEditBranch",
    components: {
        VueCropper,
    },
    props: {
        addEditBranchData: Object,
    },
    data() {
        return {
            validate: object({
                name: string()
                    .min(2, "Please enter branch name")
                    .required("Please enter branch name")
                    .nullable(),
                address: string()
                    .min(2, "Please enter branch address")
                    .required("Please enter branch address")
                    .nullable(),
                city: string()
                    .min(2, "Please enter branch city")
                    .required("Please enter branch city")
                    .nullable(),
                state: string()
                    .min(2, "Please enter branch state")
                    .required("Please enter branch state")
                    .nullable(),
                zipcode: string()
                    .min(2, "Please enter branch zipcode")
                    .required("Please enter branch zipcode")
                    .nullable(),
                unit: string()
                    .min(2, "Please enter branch unit")
                    .required("Please enter branch unit")
                    .nullable(),
            }),
            branch: {},
            loadingSaveChanges: false,
            imagePreview: null,
            showCropper: false,
            logoError: false,
            popupTitle: null,
            popupButtonText: null,
            imageSelected: false,
        };
    },
    created() {
        this.branch = { ...this.addEditBranchData };
        var action = this.branch.id ? "Update" : "Add";
        var itemType = "Branch";
        this.popupTitle = action + " " + itemType;
        this.popupButtonText = action + " " + itemType;
    },
    methods: {
        onFileChange(e) {
            this.logoError = false;
            const file = e.target.files[0];
            this.imageToBase64(file);
        },
        imageToBase64(file) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.imagePreview = reader.result;
                this.showCropper = true;
            };
        },
        deletePreview() {
            this.imagePreview = null;
        },
        closeCropper() {
            this.showCropper = false;
            this.imagePreview = null;
            this.$refs.image.value = "";
        },
        cropImage() {
            // get image data for post processing, e.g. upload or setting image src
            this.branch.logo = this.$refs.cropper.getCroppedCanvas().toDataURL();
            this.showCropper = false;
            this.$refs.image.value = "";
            this.imageSelected = true;
        },
        submit() {
            var formData = new FormData();

            if (this.branch.logo) {
                for (var key in this.branch) {
                    if (key != "logo") {
                        formData.append(key, this.branch[key]);
                    }
                }

                if (this.branch.logo && this.imageSelected) {
                    formData.append("logo", F.dataURIToBlob(this.branch.logo), "logo.png");
                }

                this.loadingSaveChanges = true;

                var url = "bar/add-branch";
                if (this.branch.id) {
                    url = "bar/branch-update";
                }
                this.$api
                    .post(url, formData)
                    .then((response) => {
                        this.$toast.info(response.message);
                        this.loadingSaveChanges = false;
                        this.$emit("closeAddEdit", true);
                    })
                    .catch(() => {
                        this.loadingSaveChanges = false;
                    });
            } else {
                this.logoError = "Please upload logo";
            }
        },
    },
};
</script>