<template>
  <div>
    <h2 class="page-title">Account Details</h2>
    <div class="w-full lg:w-2/3 xl:w-1/3 mx-auto">
      <Form @submit="submit" :validation-schema="validate">
        <div class="grid grid-cols-2 gap-x-3 mt-12">
          <div class="input-box">
            <label for="first_name" class="text-xl">First Name</label>
            <div>
              <Field
                id="first_name"
                name="first_name"
                type="text"
                required
                class="dom-border-input"
                v-model="profile.first_name"
              />
              <ErrorMessage name="first_name" class="input-error" />
            </div>
          </div>
          <div class="input-box">
            <label for="last_name" class="text-xl">Last Name</label>
            <div>
              <Field
                id="last_name"
                name="last_name"
                type="text"
                required
                class="dom-border-input"
                v-model="profile.last_name"
              />
              <ErrorMessage name="last_name" class="input-error" />
            </div>
          </div>
        </div>

        <div class="input-box">
          <label for="email-address" class="text-xl">Email Address</label>
          <div>
            <Field
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              class="dom-border-input"
              v-model="profile.email"
            />
            <ErrorMessage name="email" class="input-error" />
          </div>
        </div>

        <div class="input-box">
          <label for="phone_number" class="text-xl">Phone #</label>
          <div>
            <Field
              id="phone"
              name="phone"
              type="tel"
              required
              class="dom-border-input"
              v-model="profile.phone"
            />
            <ErrorMessage name="phone" class="input-error" />
          </div>
        </div>

        <div class="input-box">
          <label for="business_name" class="text-xl">Business Name</label>
          <div>
            <Field
              id="business_name"
              name="business_name"
              type="business_name"
              required
              class="dom-border-input"
              v-model="profile.business_name"
            />
            <ErrorMessage name="business_name" class="input-error" />
          </div>
        </div>

        <div class="grid grid-cols-2">
          <div class="text-left mt-12">
            <btn :light="true" @clicked="showChangePassword = true">Change Password</btn>
          </div>
          <div class="text-right mt-12">
            <btn :loading="processing" :black="true" :type="'submit'">
              <div class="px-4">Save Changes</div>
            </btn>
          </div>
        </div>
      </Form>
    </div>
    <ChangePassword v-if="showChangePassword" @closeChangePassword="showChangePassword = false"></ChangePassword>
    <OTP v-if="showOTP" :email="profile.email" @closeOTP="closeOTP" />
  </div>
</template>

<script>
import ChangePassword from "./change_password";
import OTP from "./otp";
import { object, string } from "yup";
import { mapState } from "vuex";

export default {
  name: "AccountDetails",
  components: {
    ChangePassword,
    OTP,
  },
  computed: {
    ...mapState({
      user: (state) => state.User.user,
    }),
  },
  data() {
    return {
      validate: object({
        first_name: string()
          .min(2, "Please enter your first name")
          .required("Please enter your first name")
          .nullable(),
        last_name: string()
          .min(2, "Please enter your last name")
          .required("Please enter your last name")
          .nullable(),
        phone: string()
          .min(10, "Please enter your phone number")
          .required("Please enter your phone number")
          .nullable(),
        email: string()
          .email("Email must be a valid email")
          .required("Please enter your email")
          .nullable(),
        business_name: string()
          .min(2, "Please enter your business name")
          .required("Please enter your business name")
          .nullable(),
      }),
      profile: {},
      showOTP: false,
      processing: false,
      showChangePassword: false,
      OldEmail: null,
    };
  },
  created() {
    this.OldEmail = this.user.email;
    this.profile = {
      first_name: this.user.first_name,
      last_name: this.user.last_name,
      phone: this.user.phone,
      email: this.user.email,
      business_name: this.user.business_name,
    };
  },
  methods: {
    submit() {
      this.processing = true;
      this.$api
        .post("update-profile", this.profile)
        .then((response) => {
          var userData = { ...this.user };
          userData.first_name = this.profile.first_name;
          userData.last_name = this.profile.last_name;
          userData.phone = this.profile.phone;
          userData.email = this.profile.email;
          userData.business_name = this.profile.business_name;
          if (this.OldEmail !== this.profile.email) {
            this.OldEmail = response.data.email;
            this.showOTP = true;
          } else {
            this.$api.setUser(userData);
          }
          this.processing = false;
          this.$toast.info(response.message);
        })
        .catch(() => {
          this.processing = false;
        });
    },
    closeOTP() {
      this.showOTP = false;
      var userData = { ...this.user };
      userData.first_name = this.profile.first_name;
      userData.last_name = this.profile.last_name;
      userData.phone = this.profile.phone;
      userData.email = this.profile.email;
      userData.business_name = this.profile.business_name;
      this.$api.setUser(userData);
    },
  },
};
</script>