<template>
  <div class="bg-white fixed inset-0 w-full h-screen z-50 bg-opacity-50">
    <div
      class="
        bg-domWhite
        shadow-xl
        w-full
        md:w-1/3
        lg:w-1/4
        mx-auto
        mt-24
        p-5
        px-10
      "
    >
      <div class="text-2xl font-normal flow-root">
        <span class="float-left">
          {{ popupTitle }}
        </span>
        <span class="float-right">
          <img
            src="@/assets/images/close.png"
            alt=""
            class="cursor-pointer"
            @click="$emit('closeChangePassword')"
          />
        </span>
      </div>
      <Form @submit="submit" :validation-schema="validate">
        <div class="input-box">
          <label for="current_password" class="text-xl">Password</label>
          <div>
            <Field
              id="current_password"
              name="current_password"
              type="password"
              required
              class="dom-border-input"
              v-model="password.current_password"
            />
            <ErrorMessage name="current_password" class="input-error" />
          </div>
        </div>
        <div class="input-box">
          <label for="new_password" class="text-xl">New Password</label>
          <div>
            <Field
              id="new_password"
              name="new_password"
              type="password"
              required
              class="dom-border-input"
              v-model="password.new_password"
            />
            <ErrorMessage name="new_password" class="input-error" />
          </div>
        </div>
        <div class="input-box">
          <label for="confirm_password" class="text-xl"
            >Confirm New Password</label
          >
          <div>
            <Field
              id="confirm_password"
              name="confirm_password"
              type="password"
              required
              class="dom-border-input"
              v-model="password.confirm_password"
            />
            <ErrorMessage name="confirm_password" class="input-error" />
          </div>
        </div>
        <div class="my-8 text-center">
          <btn :loading="processing" :black="true" :type="'submit'">
            <span class="px-4">{{ popupButtonText }}</span>
          </btn>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { object, string } from "yup";
export default {
  name: "AddEditDrink",
  data() {
    return {
      validate: object({
        current_password: string()
          .min(6, "Password should be at least 6 characters long")
          .required("Please enter password")
          .nullable(),
        new_password: string()
          .min(6, "Password should be at least 6 characters long")
          .required("Please enter password")
          .nullable(),
        confirm_password: string()
          .required()
          .test(
            "passwords-match",
            "Passwords must match",
            (value) => this.password.new_password === value
          ),
      }),
      password: {
        current_password: null,
        new_password: null,
      },
      processing: false,
      popupTitle: null,
      popupButtonText: null,
    };
  },
  created() {
    this.popupTitle = "Change Password";
    this.popupButtonText = "Change Password";
  },
  methods: {
    submit() {
      this.processing = true;
      this.password.confirm_password = this.password.new_password;
      this.$api
        .post("change-password", this.password)
        .then((response) => {
          this.processing = false;
          this.$toast.info(response.message);
          this.$emit("closeChangePassword");
        })
        .catch(() => {
          this.processing = false;
        });
    },
  },
};
</script>