<template>
  <div>
    <div class="flex justify-between">
      <h2 class="page-title">Transactions</h2>
      <div v-if="payout">
        <div>
          <span class="page-title">${{ payout }}</span>
          <btn v-if="payout > 0"
            :as="'button'"
            class="inline-block ml-3 px-4"
            :loading="payoutProcess"
            @clicked="requestPayout">Request Payout</btn>
        </div>
        <div class="mt-1 text-sm text-gray-400">
          Amount available for payout
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-x-10 mt-14 filter gap-4">
      <div>
        <SelectWithSearch :options="branches"
          :showOptions="showOptions"
          :searchString="branchName"
          @optionClicked="branchSelected"
          @toggleOptions="showOptions = showOptions ? false : true">
          <Field name="name"
            type="text"
            required
            class="select-search-input"
            v-model="branchName"
            @keyup="changeBranchName"
            :placeholder="'Branch name'" />
        </SelectWithSearch>
      </div>
      <div class="grid grid-cols-2 gap-x-2">
        <datepicker v-model="fromDate"
          :clearable="false"
          :placeholder="'From date'" />
        <datepicker v-model="toDate"
          :clearable="false"
          :lowerLimit="fromDate"
          :placeholder="'To date'" />
      </div>
      <div class="grid grid-cols-5 gap-x-10">
        <!-- <div class="relative inline-block col-span-3">
          <div
            class="
              absolute
              inset-y-0
              left-0
              pl-3
              flex
              items-center
              pointer-events-none
            "
          >
            <span class="text-gray-500 sm:text-sm">
              <img src="@/assets/images/search.png" alt="" />
            </span>
          </div>
          <input
            type="search"
            name=""
            id=""
            class="custom-input"
            style="padding-left: 40px"
            placeholder="Search"
            v-model="searchString"
            @blur="init(1)"
            @keyup="searchStringChange"
          />
          <div
            class="
              absolute
              inset-y-0
              right-0
              pr-3
              flex
              items-center
              cursor-pointer
            "
          >
            <span class="text-gray-500 sm:text-sm">
              <img src="@/assets/images/close.png" alt="" />
            </span>
          </div>
        </div> -->
        <div class="col-span-2">
          <btn class="inline-block"
            @click="exportFile()">Export</btn>
        </div>
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <th>Branch Name</th>
          <th>Drinks</th>
          <th>Food</th>
          <th>Gratuity</th>
          <th>Total</th>
          <th>Transaction Fees</th>
          <th>Drinks On Me Fees</th>
          <!-- <th>Drinks On Me Payout</th> -->
          <th>Branch Payout</th>
        </tr>
        <!-- <tr>
          <th>Branch Name</th>
          <th>Drinks</th>
          <th>Food</th>
          <th>Gratuity</th>
          <th>Total</th>
          <th>Transaction Fees</th>
          <th>Drinks On Me Fees</th>
          <th>Drinks On Me Payout</th>
          <th>Branch Payout</th>
        </tr> -->
      </thead>
      <tbody>
        <tr v-for="transaction in transactions"
          :key="transaction.id">
          <td>{{ transaction.branch_name }}</td>
          <td>${{ truncateNumber(transaction.total_drink) }}</td>
          <td>${{ truncateNumber(transaction.total_food) }}</td>
          <td>${{ truncateNumber(transaction.gratuity) }}</td>
          <td>${{ truncateNumber(transaction.total) }}</td>
          <td>${{ truncateNumber(transaction.transaction_fees) }}</td>
          <td>${{ truncateNumber(transaction.DOM_fees) }}</td>
          <!-- <td>${{ truncateNumber(transaction.DOM_payout) }}</td> -->
          <td>${{ truncateNumber(transaction.branch_payout) }}</td>
        </tr>
      </tbody>
      <tfoot>
        <th colspan="11"
          class="text-right pt-3">
          <div class="pb-5">
            <btn :loading="pageLoading"
              :type="'button'"
              :light="true"
              :disabled="pagination.current_page == 1"
              @clicked="init(pagination.current_page - 1)">
              Previous
            </btn>
            <div class="mx-10 font-normal">
              Page {{ pagination.current_page }} of
              {{ pagination.total_pages }}
            </div>
            <btn :loading="pageLoading"
              :type="'button'"
              :light="true"
              :disabled="pagination.current_page >= pagination.total_pages"
              @clicked="init(pagination.current_page + 1)">
              Next
            </btn>
          </div>
        </th>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Datepicker from "vue3-datepicker";
import F from "../../helper/function";

export default {
  name: "transactionsList",
  components: {
    Datepicker,
  },
  data() {
    return {
      transactions: [],
      pagination: {},
      pageLoading: false,
      fromDate: null,
      toDate: null,
      branch: null,
      showOptions: false,
      branchName: null,
      searchString: null,
      payout: false,
      payoutProcess: false,
      decimalPlaces: 2,
    };
  },
  computed: {
    ...mapState({
      branches: (state) => state.Branches.branches,
    }),
  },
  watch: {
    fromDate: function () {
      this.init(1);
    },
    toDate: function () {
      this.init(1);
    },
  },
  created() {
    this.init(1);
  },
  methods: {
    init(page) {
      if (!this.payout) {
        this.$api.post("bar/get-payout-amount").then((response) => {
          this.payout = this.truncateNumber(response.data);
        });
      }

      this.pageLoading = true;

      var url = "bar/get-transaction-history?page=" + page;
      if (this.branch) {
        url += "&branch_id=" + this.branch.id;
      }
      if (this.fromDate) {
        let formattedDate =
          this.fromDate.getFullYear() +
          "-" +
          (this.fromDate.getMonth() + 1) +
          "-" +
          this.fromDate.getDate();
        url += "&from_date=" + formattedDate;
      }
      if (this.toDate) {
        let formattedDate =
          this.toDate.getFullYear() +
          "-" +
          (this.toDate.getMonth() + 1) +
          "-" +
          this.toDate.getDate();
        url += "&to_date=" + formattedDate;
      }
      if (this.searchString) {
        url += "&search=" + this.searchString;
      }

      if (this.export) {
        url += "&is_export=1";
      }

      this.$api.get(url).then((response) => {
        if (this.export) {
          this.export = false;
          const blob = new Blob([response]);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "file.csv";
          link.click();
          URL.revokeObjectURL(link.href);
        } else {
          this.transactions = response.transaction_history.data;
          this.pagination = {
            current_page: response.transaction_history.current_page,
            total_pages: response.transaction_history.total,
          };
          this.pageLoading = false;
        }
      });
    },
    branchSelected(branch) {
      this.showOptions = false;
      this.branchName = branch.name;
      this.branch = branch;
      this.init(1);
    },
    changeBranchName() {
      if (this.branchName.length > 2) {
        this.showOptions = true;
      } else {
        if (this.branchName.length === 0) {
          this.branch = null;
          this.init(1);
        }
        this.showOptions = false;
      }
    },
    formattedDate(date) {
      let formattedDate = new Date(Date.parse(date));
      return (
        formattedDate.getMonth() +
        1 +
        "/" +
        formattedDate.getDate() +
        "/" +
        formattedDate.getFullYear()
      );
    },
    formattedTime(date) {
      let formattedDate = new Date(Date.parse(date));
      return formattedDate.getHours() + ":" + formattedDate.getMinutes();
      // let hours = formattedDate.getHours() > 9 ? formattedDate.getHours() : '0' + formattedDate.getHours()
      // let minutes = formattedDate.getMinutes() > 9 ? formattedDate.getMinutes() : '0' + formattedDate.getMinutes()
      // return hours + ":" + minutes;
    },
    searchStringChange(e) {
      if (e.keyCode === 13) {
        this.init(1);
      }
    },
    requestPayout() {
      this.payoutProcess = true;
      this.$api.post("bar/payout-request").then(() => {
        this.$api.post("bar/get-payout-amount").then((response) => {
          console.log(response);
          this.payout = this.truncateNumber((response.data));
          this.payoutProcess = false;
        });
      });
    },
    exportFile() {
      this.export = true;
      this.init(1);
    },

    truncateNumber(value) {
      return F.truncateNumber(value, 2);
    }
  },
};
</script>