<template>
  <div>
    <h2 class="page-title">Events</h2>
    <table>
      <thead>
        <tr>
          <th>
            <span class="sm:h-10 sm:w-10 lg:h-auto lg:w-auto block">Image</span>
          </th>
          <th>Name</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Start Time</th>
          <th>End Time</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="event in events"
          :key="event.id"
          :class="{ deleting: deletingRow === event.id }"
        >
          <td class="w-20 border-0">
            <ig :src="event.image" :class="'h-10 w-10 rounded-full'"></ig>
          </td>
          <td class="w-1/3">{{ event.title }}</td>
          <td>{{ event.start_date }}</td>
          <td>{{ event.end_date }}</td>
          <td>{{ event.start_time }}</td>
          <td>{{ event.end_time }}</td>
          <td class="action inactive" @click="toggleActionMeun(event.id)">
            Manage
            <ul v-show="openAction === event.id">
              <li class="p-3" @click="addEdit({ ...event })">Edit</li>
              <li class="p-3 bg-red-400 text-white" @click="destroy(event)">
                Delete
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <th colspan="3" class="text-left p-3">
          <btn :type="'button'" @clicked="addEdit({ ...newEventData })">
            <span class="block px-10">Add New Event</span>
          </btn>
        </th>
        <th colspan="5" class="text-right pt-3">
          <btn
            :loading="pageLoading"
            :type="'button'"
            :light="true"
            :disabled="pagination.current_page <= 1"
            @clicked="init(pagination.current_page - 1, 'drink')"
          >
            Previous
          </btn>
          <div class="mx-10 font-normal">Page 1 of 1</div>
          <btn
            :loading="pageLoading"
            :type="'button'"
            :light="true"
            :disabled="pagination.current_page >= pagination.total_pages"
            @clicked="init(pagination.current_page + 1, 'drink')"
          >
            Next
          </btn>
        </th>
      </tfoot>
    </table>
    <AddEditPopup
      v-if="showAddEditPopup"
      @closeAddEdit="closeAddEdit"
      :addEditEventData="addEditData"
    ></AddEditPopup>
  </div>
</template>

<script>
import AddEditPopup from "./add_edit";

export default {
  name: "eventList",
  components: {
    AddEditPopup,
  },
  data() {
    return {
      events: [],
      openAction: 0,
      showAddEditPopup: false,
      newEventData: {
        title: null,
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        image: "",
        // is_sponsored: 1
      },
      addEditData: {},
      pagination: {},
      pageLoading: false,
      deletingRow: null,
    };
  },
  created() {
    this.init(1);
  },
  methods: {
    init(page) {
      this.pageLoading = true;
      this.$api.get("bar/event-list?page=" + page).then((response) => {
        this.events = response.event.data;
        this.pagination = response.event.pagination;
        this.pageLoading = false;
      });
    },
    toggleActionMeun(id) {
      this.openAction = this.openAction === id ? 0 : id;
    },
    addEdit(item) {
      if (item.id) {
        // var start_time = new Date(
        //   item.start_date + " " + this.ampmto24(item.start_time)
        // );
        // var end_time = new Date(
        //   item.end_date + " " + this.ampmto24(item.end_time)
        // );
        var start_time_all = item.start_time.split(":")
        var start_time_min_am = start_time_all[1].split(" ")
        var start_time = {
          hh: ('0' + start_time_all[0]).slice(-2),
          mm: ('0' + start_time_min_am[0]).slice(-2),
          A: start_time_min_am[1].toUpperCase(),
        };

        var end_time_all = item.end_time.split(":")
        var end_time_min_am = end_time_all[1].split(" ")
        var end_time = {
          hh: ('0' + end_time_all[0]).slice(-2),
          mm: ('0' + end_time_min_am[0]).slice(-2),
          A: end_time_min_am[1].toUpperCase(),
        };

        item.start_date = new Date(item.start_date);
        item.start_time = start_time;
        item.end_date = new Date(item.end_date);
        item.end_time = end_time;
      }

      this.addEditData = item;
      this.showAddEditPopup = true;
    },
    closeAddEdit(reload = false) {
      if (reload) {
        this.init(1);
      }
      this.showAddEditPopup = false;
    },
    ampmto24(time12h) {
      const [time, modifier] = time12h.split(" ");

      let [hours, minutes] = time.split(":");

      if (hours === "12") {
        hours = "00";
      }

      if (modifier.toUpperCase() === "PM") {
        hours = parseInt(hours, 10) + 12;
      }

      return `${hours}:${minutes}:00`;
    },
    destroy(event) {
      if (confirm("It will delete " + event.title + " event")) {
        this.deletingRow = event.id;
        this.$api.post("bar/delete-event", { id: event.id }).then(() => {
          this.init(this.pagination.current_page);
        });
      }
    }
  },
};
</script>