<template>
  <div class="bg-white fixed inset-0 w-full h-screen z-50 bg-opacity-50">
    <div
      class="
        bg-domWhite
        shadow-xl
        w-full
        md:w-1/3
        lg:w-1/4
        mx-auto
        mt-24
        p-5
        px-10
      "
    >
      <div class="text-2xl font-normal flow-root">
        <span class="float-left">
          {{ popupTitle }}
        </span>
        <span class="float-right">
          <img
            src="@/assets/images/close.png"
            alt=""
            class="cursor-pointer"
            @click="$emit('closeOTP')"
          />
        </span>
      </div>
      <Form @submit="submit">
        <div class="input-box">
          <!-- <label for="otp" class="text-xl">Enter OTP</label> -->
          <div>
            <Field
              id="otp"
              name="otp"
              type="number"
              max="9999"
              required
              class="dom-border-input otp-input text-2xl"
              v-model="otp"
              @keyup="setOTP"
            />
          </div>
        </div>
        <div class="my-8 text-center">
          <btn :loading="processing" :black="true" :type="'submit'">
            <span class="px-4">{{ popupButtonText }}</span>
          </btn>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
export default {
  name: "OTP",
  data() {
    return {
      otp: null,
      processing: false,
      popupTitle: null,
      popupButtonText: null,
    };
  },
  props: {
    email: {
      type: String,
      default: ''
    },
  },
  created() {
    this.popupTitle = "OTP";
    this.popupButtonText = "Submit";
  },
  methods: {
    submit() {
      this.processing = true;
      this.$api
        .post("bar/verify-otp", {
          otp: this.otp,
          email: this.email,
        })
        .then((response) => {
          this.processing = false;
          this.$toast.info(response.message);
          if(response.status){
            this.$emit("closeOTP");
          }
        })
        .catch(() => {
          this.processing = false;
        });
    },
    setOTP() {
      if (this.otp.length > 4) {
        this.otp = this.otp.slice(0, 4);
      }
    },
  },
};
</script>